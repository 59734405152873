import { connect } from "react-redux";

import { fetchPartners, loadPendingReviewAndKYCCount } from "actions/actions";
import { receiveToken } from "actions/session_actions";
import App from "App";

const mapDispatchToProps = (dispatch) => ({
  receiveToken: (token) => dispatch(receiveToken(token)),
  loadPendingReviewAndKYCCount: () => loadPendingReviewAndKYCCount(dispatch),
  fetchPartners: () => fetchPartners(dispatch),
});

export default connect(null, mapDispatchToProps)(App);
