import React, { useState } from "react";
import { Loader } from "components/reusable/Loader";
import SmileLock from "resources/img/brand/smile-id__symbol--32x32.svg";
import SmileLockWhite from "resources/img/brand/smile-id__symbol--white.svg";
import Icon2FA from "resources/img/icons/2FA.svg";
import * as APIUtil from "util/api_util";
import { copy } from "util/format_helpers";
import { decode } from "util/route_util";

function SetUpTFA({ modal = false, dismissModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [OTPSecret, setOTPSecret] = useState("");
  const [QRCode, setQRCode] = useState("");

  const [QRCodeRequested, setQRCodeRequested] = useState(false);

  const [OTPSetUpSuccessful, setOTPSetUpSuccessful] = useState(false);
  const [OTPCode, setOTPCode] = useState("");
  const [OTPCodeConfirmed, setOTPCodeConfirmed] = useState("");
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [copiedText, setCopiedText] = useState("");

  const enable = async (e) => {
    e.preventDefault();

    const userIsLoggedIn = !!localStorage.token;

    const payload = userIsLoggedIn
      ? decode(localStorage.token)
      : localStorage.preOTPUser;
    setIsLoading(true);
    setOTPSecret("");
    setQRCode("");

    try {
      const resp = await APIUtil.enable2FA(payload);

      if (resp.errors) throw resp.errors;

      setIsLoading(false);
      setQRCode(resp.qrcode);
      setOTPSecret(resp.secret);
      setQRCodeRequested(true);
    } catch (e) {
      const error = Array.isArray(e) ? e.join("\n") : e;

      setIsLoading(false);
      setError(error);
    }
  };

  const confirmOTP = async (e) => {
    e.preventDefault();

    try {
      if (!OTPCode || OTPCode.length !== 6) {
        throw new Error("OTP Code should be 6 digits only");
      }

      const user = localStorage.token
        ? decode(localStorage.token)
        : JSON.parse(localStorage.preOTPUser);
      const payload = {
        ...user,
        otp_code: OTPCode,
      };
      setIsLoading(true);

      const resp = await APIUtil.confirm2FAOTP(payload);

      if (resp.errors) throw resp.errors;
      setIsLoading(false);
      setOTPSetUpSuccessful(true);
      setOTPCodeConfirmed(resp.message);
      setRecoveryCodes(resp.recovery_codes);
      if (localStorage.preOTPUser) localStorage.removeItem("preOTPUser");
      if (resp.auth_token) localStorage.setItem("token", resp.auth_token);
    } catch (e) {
      const error =
        e instanceof Error ? e.message : Array.isArray(e) ? e.join("\n") : e;

      setIsLoading(false);
      setError(error);
    }
  };

  const redirectHome = (e) => {
    e.preventDefault();

    if (modal) {
      dismissModal(true);
      return;
    }

    if (localStorage.preOTPUser) {
      localStorage.removeItem("preOTPUser");
    }

    window.location = "/";
  };

  const copyCodes = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const text = copy(
      "backup-codes-string",
      "Backup Codes copied to clipboard!",
    );
    setCopiedText(text.message);
  };

  return (
    <>
      {!QRCode && !OTPSecret && !QRCodeRequested && (
        <div className={`${modal ? "" : "cover"} | bgc-dark | authentication`}>
          <div className="center" style={{ maxInlineSize: "none" }}>
            <div
              style={{ color: "#fff", backgroundColor: "#1A3359" }}
              className="box stack | account-settings"
            >
              <div className="cluster">
                <div className="stack">
                  {!modal && (
                    <div>
                      <img
                        className="smile-login__icon"
                        src={SmileLockWhite}
                        alt="smile logo"
                      />
                    </div>
                  )}
                  <h2 style={{ fontSize: "24px" }}>
                    Set Up Two-Factor Authentication On Your Account
                  </h2>

                  {modal ? (
                    <p>
                      Prevent unauthorized access to your account by adding an
                      additional layer of security. When you sign in, you will
                      need a security code in addition to your password.
                    </p>
                  ) : (
                    <p>
                      Your organization requires two-factor authentication. This
                      means to sign in, you will need a security code in
                      addition to your password.
                    </p>
                  )}

                  <p>
                    To setup, you will need a two-factor authentication app on
                    your phone. Although we support most mobile authenticator
                    apps, we recommend using cloud-based TOTP apps such as
                    Microsoft Authenticator, Authy, LastPass Authenticator or
                    1Password.
                  </p>
                </div>

                <div>
                  <img src={Icon2FA} alt="" />
                </div>
              </div>

              <hr />

              <div className="cluster" data-justify-content="space-between">
                <br />
                <div style={{ "--space": ".5rem" }} className="cluster">
                  <button
                    type="button"
                    className="button button--outline"
                    onClick={redirectHome}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="button button--primary"
                    onClick={enable}
                  >
                    {isLoading && (
                      <>
                        <Loader />
                        &nbsp;&nbsp;
                      </>
                    )}
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {QRCode && OTPSecret && QRCodeRequested && !OTPCodeConfirmed && (
        <div className={`${modal ? "" : "cover"} | bgc-dark | authentication`}>
          <div className="center" style={{ maxInlineSize: "none" }}>
            <div
              style={{ color: "#000", backgroundColor: "#fff" }}
              className="box stack | account-settings"
            >
              <div className="cluster">
                <div className="stack">
                  {!modal && (
                    <div>
                      <img
                        className="smile-login__icon"
                        src={SmileLock}
                        alt="smile logo"
                      />
                    </div>
                  )}
                  <h2 style={{ fontSize: "24px" }}>
                    Set Up Two-Factor Authentication On Your Account
                  </h2>

                  <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
                    Open your Authenticator App, then scan the QR Code, or
                    manually enter this code{" "}
                    <strong style={{ color: "#2F51A0" }}>{OTPSecret}</strong>.
                  </p>
                </div>

                <div className="qrcode">
                  {/* eslint-disable react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: QRCode }} />
                  {/* eslint-enable react/no-danger */}
                </div>
              </div>

              <form noValidate name="confirmOTPForm">
                <div
                  className="cluster"
                  data-align-items="start"
                  data-justify-content="space-between"
                >
                  <label style={{ fontSize: "18px" }} htmlFor="totp-token">
                    <strong>Enter OTP Generated in Authenticator App</strong>
                  </label>

                  <div className="stack">
                    <input
                      autoFocus
                      className="input-text"
                      id="totp-token"
                      name="totp-token"
                      autoComplete="one-time-code"
                      placeholder="Enter OTP"
                      required
                      type="text"
                      pattern="([0-9]{6})|([0-9a-fA-F]{5}-?[0-9a-fA-F]{5})"
                      value={OTPCode}
                      onChange={(e) => setOTPCode(e.target.value)}
                    />
                    {error && <p className="color-error">{error}</p>}
                  </div>
                </div>

                <hr style={{ borderColor: "#E1E6EE" }} />

                <div className="cluster" data-justify-content="space-between">
                  <br />
                  <div style={{ "--space": ".5rem" }} className="cluster">
                    <button
                      type="button"
                      className="button button--outline"
                      onClick={redirectHome}
                    >
                      Cancel
                    </button>

                    <button
                      className="button button--primary"
                      onClick={confirmOTP}
                    >
                      {isLoading && (
                        <>
                          <Loader />
                          &nbsp;&nbsp;
                        </>
                      )}
                      Submit OTP
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {OTPSetUpSuccessful && OTPCodeConfirmed && recoveryCodes && (
        <div className={`${modal ? "" : "cover"} | bgc-dark | authentication`}>
          <div className="center">
            <div className="box auth-box">
              <div className="stack" style={{ "--space": "1.5rem" }}>
                <div className="stack" data-variant="small">
                  {!modal && (
                    <div className="center">
                      <img
                        className="smile-login__icon"
                        src={SmileLock}
                        alt="smile logo"
                      />
                    </div>
                  )}
                  <h2
                    style={{
                      fontSize: "24px",
                      lineHeight: 1.2,
                      textAlign: "center",
                    }}
                  >
                    Set Up Two-Factor Authentication On Your Account
                  </h2>

                  <p>{OTPCodeConfirmed}</p>

                  <p>Your backup codes are displayed below.</p>

                  <p>
                    Please store them somewhere safe, for use in case you lose
                    your device.
                  </p>

                  <div className="center | backup-codes">
                    {recoveryCodes.map((code, idx) => (
                      <p key={`${code}-${idx}`}>{code}</p>
                    ))}
                  </div>

                  {copiedText && <p className="success">{copiedText}</p>}

                  <p className="visually-hidden" id="backup-codes-string">
                    {recoveryCodes.join(", ")}
                  </p>

                  <button
                    className="btn btn--link copy-btn"
                    type="button"
                    onClick={copyCodes}
                  >
                    <span>Copy Backup Codes</span>
                  </button>

                  <button
                    className="btn-primary"
                    type="button"
                    onClick={redirectHome}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SetUpTFA;
