import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import useAuthenticatedUser from "hooks/useAuthenticatedUser";
import { buildUrl } from "util/api_util";
import { nonSortableHeaders } from "util/table_headers";

type PaginationState = {
  desc: boolean;
  order_by: string;
  page: number;
};

type Props = {
  fetchResources: (tableTyple: string, state: PaginationState) => void;
  pagination: PaginationState;
  sortField: string;
  tableType: string;
  className?: string;
  title: string;
  inert: boolean;
};

const TableHeader: React.FC<Props> = ({
  fetchResources,
  pagination,
  sortField,
  tableType,
  className,
  title,
  inert,
}) => {
  const history = useHistory();
  const { userType } = useAuthenticatedUser();
  const [headerPagination, setPagination] = React.useState<PaginationState>(
    () => pagination,
  );

  const onFetchResources = () => {
    const newPaginationState = {
      ...headerPagination,
      desc: !headerPagination.desc,
      order_by: sortField,
      page: 1,
    };
    setPagination(newPaginationState);
    fetchResources(tableType, newPaginationState);
    if (tableType === "smile_results") {
      history.push(`/partner${buildUrl(tableType, newPaginationState)}`);
      return;
    }
    if (tableType.includes("reviews")) {
      const basePath = userType === "admin" ? "/admin" : "/reviewer";
      history.push(`/${basePath}${buildUrl(tableType, newPaginationState)}`);
    }
    history.push(`/admin${buildUrl(tableType, newPaginationState)}`);
  };

  const thClasses = classNames(className, {
    selected: sortField === pagination.order_by,
  });

  const icon = headerPagination.desc ? "arrow-down" : "arrow-up";
  const span = (
    <a onClick={onFetchResources}>
      <span className={icon} />
    </a>
  );

  if (tableType === "reviews") {
    return (
      <li className={thClasses}>
        <a onClick={onFetchResources}>{title}</a>
        {sortField === pagination.order_by && span}
      </li>
    );
  }
  if (inert || nonSortableHeaders.includes(title)) {
    return (
      <th className={thClasses}>
        <p>{title} </p>
      </th>
    );
  }

  return (
    <th className={thClasses}>
      <a className="head-link" onClick={onFetchResources}>
        {title}
      </a>
      {span}
    </th>
  );
};

export default TableHeader;
