import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import Card from "components/reusable/card";
import Header from "components/reusable/header";
import { Loader } from "components/reusable/Loader";
import { useQueryParams } from "hooks/useQueryParams";
import SmileLock from "resources/img/brand/smile-id__symbol--white.svg";
import Tick from "resources/img/icons/tick--filled-green.svg";
import * as APIUtil from "util/api_util";

export function SlackAuth(props) {
  const { teamId, channelId } = useQueryParams();
  const [state, setState] = useState({
    email: "",
    password: "",
    slack_team: teamId,
  });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });
  const handleLogin = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const resp = await APIUtil.slackAuth({ ...state });
      if (!resp.errors) {
        return history.replace(
          `/slack/installed?teamId=${teamId}&channelId=${channelId}`,
        );
      }
      let error = resp.errors[0];
      if (error === "Incorrect Email" || error === "Incorrect Password") {
        error =
          "Invalid credentials. Please check the username & password provided.";
      }
      setError(error);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError("Authentication Failed.");
    }
  };

  const textErrors = (
    <div
      className={`message message--login ${error ? "message--error" : ""}`}
      style={{ display: error ? "block" : "none" }}
    >
      {error}
    </div>
  );
  let passwordStyles = "";
  let emailStyles = "";
  if (props.errors) {
    if (props.errors[0].includes("Password")) {
      passwordStyles = "danger";
    } else {
      emailStyles = "danger";
    }
  }

  return (
    <div>
      <Header envSlider={false} />
      <div className="newsmile-container__login">
        <div className="smile-login__container">
          <div className="smile-login__icon-container">
            <img
              className="smile-login__icon"
              src={SmileLock}
              alt="smile logo"
            />
          </div>
          <div>
            <div className="smile-login__heading">Smile ID</div>
            {textErrors}
            <form className="smile-form smile-form--login" id="new_account">
              <div className="field">
                <br />
                <label>Email Address</label>
                <input
                  autoFocus="autofocus"
                  type="email"
                  id="email"
                  name="email"
                  className={`input--line ${emailStyles}`}
                  onChange={onChange}
                  value={state.email}
                />
              </div>

              <div className="field">
                <br />
                <label> Password </label>
                <input
                  autoComplete="off"
                  type="password"
                  id="password"
                  name="password"
                  className={`input--line ${passwordStyles}`}
                  onChange={onChange}
                  value={state.password}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      handleLogin(e);
                    }
                  }}
                />
              </div>

              <br />

              <div className="button-container--center">
                <button
                  onClick={handleLogin}
                  className="btn btn-primary smile-login__button"
                  type="button"
                >
                  {loading && <Loader />}&nbsp;&nbsp; Sign In
                </button>
              </div>
              <div className="fixed-dots--bottom-across" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SlackConnect() {
  const { teamId, channelId } = useQueryParams();
  window.open(`slack://app?team=${teamId}&id=${channelId}`, "_self");
  return (
    <div className="newsmile-container__login">
      <div className="smile-card__login_container">
        <Card
          cardClass="smile-card--centered"
          heading="Successfully installed Smile ID Bot"
          icon={Tick}
        />
      </div>
    </div>
  );
}
