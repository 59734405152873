import React from "react";
import { Link } from "react-router-dom";

function ReviewerSplash() {
  return (
    <div>
      <div>
        <h4>Go To:</h4>
        <ul className="list-group col-md-2">
          <li className="list-group-item list-group-item-action list-group-item-info">
            <Link to="/reviewer/reviews">Open Reviews</Link>
          </li>
          <li className="list-group-item list-group-item-action list-group-item-info">
            <Link to="/reviewer/reviews/completed">Completed Reviews</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ReviewerSplash;
