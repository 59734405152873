import React from "react";
import useEnvironment from "hooks/useEnvironment";
import ArrowRight from "resources/img/icons/arrowcircleright.svg";
import TemplateIcon from "resources/img/icons/link-template-icon.svg";
import SettingIcon from "resources/img/icons/settings-icon.svg";
import Mockup from "resources/img/smile-links-mockup.png";
import { css } from "util/format_helpers";
import PageHeader from "../../page_header/PageHeader";
import styles from "./smile_links.module.css";
import { StyledButton } from "./StyledButton";

export default function SmileLinks() {
  useEnvironment("../:environment/smile_links");

  return (
    <div className={css("new-typography", styles.container)}>
      <PageHeader
        containerClassName={styles.title}
        title="Smile Links"
        subTitle="Create a verification link in less than 5 minutes without code."
        link="https://docs.usesmileid.com/integration-options/no-code/smile-links"
      />
      <div className={styles.createLinkContainer}>
        <div>
          <div className="h2">New link</div>
          <div className={css("h5", styles.description)}>
            Create a custom Smile Link from scratch
          </div>
          <StyledButton
            isActive
            href="smile_links/new"
            className={styles.createLink}
          >
            <img src={ArrowRight} alt="arrow right" /> Create New Link
          </StyledButton>
        </div>
        <img className={styles.mockup} src={Mockup} alt="smile link mockup" />
      </div>
      <div className={styles.cards}>
        <a href="smile_links/manage" className={css(styles.card, styles.link)}>
          <div className={styles.cardsIconContainer}>
            <img src={SettingIcon} alt="settings icon" />
          </div>
          <div>
            <div className="h2">Manage Links</div>
            <div className={css("h3", styles.cardsDescription)}>
              Manage your published Smile Links
            </div>
            <div className="h3">Links Dashboard</div>
          </div>
        </a>
        <div className={styles.card}>
          <div className={styles.cardsIconContainer}>
            <img src={TemplateIcon} alt="link template icon" />
          </div>
          <div>
            <div className="h2">Use a template</div>
            <div className={css("h3", styles.cardsDescription)}>
              Create a Smile Link with pre-filled templates. (eg. All Kenyan
              IDs)
            </div>
            <a aria-disabled disabled className={css("h3", styles.link)}>
              Available soon
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
