import React from "react";
import ModalWrapper from "components/reusable/web_links/ModalWrapper";
import { StyledButton } from "components/reusable/web_links/StyledButton";
import WarningIcon from "resources/img/icons/auto-pay-warning.svg";
import "./auto_pay.scss";

function ConfirmAutoPayOffModal({ isOpen, onClose, onConfirm }) {
  return (
    <ModalWrapper hideBackButton isOpen={isOpen} onClose={() => onClose(false)}>
      <div className="confirm-auto-pay-off-modal auto-pay new-styles new-typography">
        <img src={WarningIcon} alt="" />
        <p className="h2">Are you certain you wish to disable Auto-pay?</p>
        <div className="modal__actions">
          <StyledButton
            className="danger-btn h3"
            variant="none"
            onClick={() => onConfirm()}
          >
            Turn off Auto-Pay
          </StyledButton>
          <StyledButton
            className="btn-white h3 self-flex-right"
            onClick={() => onClose(false)}
            variant="none"
          >
            Cancel
          </StyledButton>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ConfirmAutoPayOffModal;
