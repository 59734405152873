import React, { Component } from "react";
import moment from "moment";
import CompareReviewContainer from "containers/reusable/compare_review_container";
import DocvReviewContainer from "containers/reusable/DocVReviewContainer";
import IDCardReviewContainer from "containers/reusable/id_card_review_container";
import Paginated from "containers/reusable/paginated";
import SortableHeaderContainer from "containers/reusable/SortableHeaderContainer";
import SpoofReviewContainer from "containers/reusable/spoof_review_container";
import UpdateImageReviewContainer from "containers/reusable/update_image_review_container";
import {
  adjustReview,
  amendUrl,
  buildState,
  getEditReview,
} from "util/api_util";
import ErrorAndMessageBar from "./error_and_message_bar";

class Reviews extends Component {
  constructor(props) {
    super(props);
    const queryState = buildState(this.props.history.location.search);
    this.state = {
      reviews: [],
      error: null,
      message: null,
      selectedReview: null,
      selectedReviewID: null,
      // pagination and filtering
      number_of_items: queryState.number_of_items || 20,
      page: queryState.page || 1,
      partner: { value: queryState.partner },
      last_job_key: queryState.last_job_key,
      first_job_key: queryState.first_job_key,
      startDate: queryState.startDate
        ? moment(queryState.startDate.replace(/(%22)/g, ""))
        : undefined,
      endDate: queryState.endDate
        ? moment(queryState.endDate.replace(/(%22)/g, ""))
        : undefined,
      query: queryState.query,
      job_type: { value: queryState.job_type },
    };
    this.fetchReview = this.fetchReview.bind(this);
    this.updateAfterReview = this.updateAfterReview.bind(this);
    this.getData = this.getData.bind(this);
    this.redirect = this.redirect.bind(this);
    this.changeNumberOfItems = this.changeNumberOfItems.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  componentWillUnmount() {
    this.props.removeReviewsState();
  }

  componentDidMount() {
    this.getData();
  }

  handlePageChange(page) {
    let last_job_key;
    let first_job_key;
    const { reviews } = this.state;
    if (page > this.state.page) {
      last_job_key = reviews[reviews.length - 1].id;
    } else if (page < this.state.page) {
      first_job_key = reviews[0].id;
    }
    this.setState(
      {
        page,
        last_job_key,
        first_job_key,
      },
      () => {
        this.getData();
      },
    );
  }

  redirect(data) {
    this.props.history.push(`job/${data.job_id}`);
  }

  getData() {
    const keys = [
      "number_of_items",
      "page",
      "partner",
      "query",
      "last_job_key",
      "first_job_key",
      "startDate",
      "endDate",
      "query",
      "partner",
      "job_type",
    ];
    const request = {};
    keys.forEach((key) => {
      if (this.state[key]) request[key] = this.state[key];
    });
    const url = amendUrl("admin/reviews/completed/", request);
    this.props.history.push(url);

    this.setFetchState();
    this.props.fetchResources("reviews/completed", request).then(
      (resp) => {
        this.setState({
          page: parseInt(resp.reviewsAndCount.pagination.page, 10),
          reviews: resp.reviewsAndCount.reviews,
          total: resp.reviewsAndCount.pagination.nextPage,
        });
        this.setFetchState();
      },
      (error) => {
        console.error(error);
      },
    );
  }

  componentDidUpdate(props) {
    if (props.displayEnvironment !== this.props.displayEnvironment) {
      this.getData();
    }
  }

  changeNumberOfItems(value) {
    this.setState({ number_of_items: value }, () => {
      this.getData();
    });
  }

  handleChange(key, value) {
    this.setState({
      [key]: value,
    });
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        page: 1,
      },
      () => {
        this.getData();
      },
    );
  }

  clearSearch() {
    this.setState(
      {
        page: 1,
        startDate: undefined,
        endDate: undefined,
        query: "",
        partner: "",
        job_type: "",
      },
      () => {
        this.getData();
      },
    );
  }

  setFetchState() {
    this.setState({
      fetchingData: !this.state.fetchingData,
    });
  }

  fetchReview(id) {
    getEditReview(id).then((resp) => {
      this.setState({
        error: resp.error,
        message: resp.message,
        selectedReview: resp.review,
        selectedReviewID: resp.review.id,
      });
    });
  }

  updateAfterReview(resp) {
    this.setState({
      error: resp.error,
      message: resp.message,
      selectedReview: null,
      selectedReviewID: null,
    });
    this.getData();
  }

  render() {
    const { reviews } = this.state;

    const tableData = [];
    for (let i = 0; i < reviews.length; i += 1) {
      const review = reviews[i];
      if (review.purpose === "compare") {
        if (this.state.selectedReviewID === review.id) {
          tableData[i] = (
            <div key={review.id} className="review-container">
              <ul>
                <CompareReviewContainer
                  review={review}
                  callback={this.updateAfterReview}
                  endpoint={adjustReview}
                  counter={this.state.counter}
                  countdown={this.countdown}
                  undo={this.undoLastreview}
                  next={this.nextReview}
                />
              </ul>
              <hr />
            </div>
          );
        } else {
          tableData[i] = (
            <div key={review.id} className="review-container">
              <ul>
                <CompareReviewContainer
                  review={review}
                  callback={this.updateAfterReview}
                  endpoint={adjustReview}
                  counter={this.state.counter}
                  countdown={this.countdown}
                  undo={() => this.fetchReview(review.id)}
                  next={this.nextReview}
                  completed
                />
              </ul>
              <hr />
            </div>
          );
        }
      } else if (review.purpose === "update_image") {
        if (this.state.selectedReviewID === review.id) {
          tableData[i] = (
            <div key={review.id} className="review-container">
              <ul>
                <UpdateImageReviewContainer
                  review={review}
                  callback={this.updateAfterReview}
                  endpoint={adjustReview}
                  counter={this.state.counter}
                  countdown={this.countdown}
                  undo={this.undoLastreview}
                  next={this.nextReview}
                  user_id={review.job.partner_params.user_id}
                />
              </ul>
              <hr />
            </div>
          );
        } else {
          tableData[i] = (
            <div key={review.id} className="review-container">
              <ul>
                <UpdateImageReviewContainer
                  review={review}
                  callback={this.updateAfterReview}
                  endpoint={adjustReview}
                  counter={this.state.counter}
                  countdown={this.countdown}
                  undo={() => this.fetchReview(review.id)}
                  next={this.nextReview}
                  completed
                  user_id={review.job.partner_params.user_id}
                />
              </ul>
              <hr />
            </div>
          );
        }
      } else if (review.purpose === "id_card") {
        if (this.state.selectedReviewID === review.id) {
          tableData[i] = (
            <div key={review.id} className="review-container">
              <ul>
                <IDCardReviewContainer
                  review={review}
                  callback={this.updateAfterReview}
                  endpoint={adjustReview}
                  counter={this.state.counter}
                  countdown={this.countdown}
                  undo={this.undoLastreview}
                  next={this.nextReview}
                />
              </ul>
              <hr />
            </div>
          );
        } else {
          tableData[i] = (
            <div key={review.id} className="review-container">
              <ul>
                <IDCardReviewContainer
                  review={review}
                  callback={this.updateAfterReview}
                  endpoint={adjustReview}
                  counter={this.state.counter}
                  countdown={this.countdown}
                  undo={() => this.fetchReview(review.id)}
                  next={this.nextReview}
                  completed
                />
              </ul>
              <hr />
            </div>
          );
        }
      } else if (review.purpose === "docv") {
        const isSelectedReview = this.state.selectedReviewID === review.id;

        tableData[i] = (
          <div key={review.id} className="review-container">
            <ul>
              <DocvReviewContainer
                review={review}
                callback={this.updateAfterReview}
                endpoint={adjustReview}
                counter={this.state.counter}
                countdown={this.countdown}
                undo={
                  isSelectedReview
                    ? this.undoLastreview
                    : () => this.fetchReview(review.id)
                }
                next={this.nextReview}
                completed={!isSelectedReview}
              />
            </ul>
            <hr />
          </div>
        );
      } else if (this.state.selectedReviewID === review.id) {
        tableData[i] = (
          <div key={review.id} className="review-container">
            <ul>
              <SpoofReviewContainer
                review={review}
                callback={this.updateAfterReview}
                endpoint={adjustReview}
                counter={this.state.counter}
                countdown={this.countdown}
                undo={this.undoLastreview}
                next={this.nextReview}
              />
            </ul>
            <hr />
          </div>
        );
      } else {
        tableData[i] = (
          <div key={review.id} className="review-container">
            <ul>
              <SpoofReviewContainer
                review={review}
                callback={this.updateAfterReview}
                endpoint={adjustReview}
                counter={this.state.counter}
                countdown={this.countdown}
                undo={() => this.fetchReview(review.id)}
                next={this.nextReview}
                completed
              />
            </ul>
            <hr />
          </div>
        );
      }
    }

    return (
      <div className="newsmile-container__main--temporarily-white dark_bg">
        <SortableHeaderContainer
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          query={this.state.query}
          partner={this.state.partner}
          jobType={this.state.job_type}
          clearSearch={this.clearSearch}
          filterByJobType
        />
        {tableData.length > 0 ? (
          <div>
            <ErrorAndMessageBar
              messages={this.state.message}
              errors={this.state.error}
            />
            <div className="review completed-review">
              <hr />
              {tableData}
            </div>
          </div>
        ) : (
          <div>No reviews were found.</div>
        )}

        <Paginated
          activePage={this.state.page}
          itemsCountPerPage={this.state.number_of_items}
          totalItems={this.state.total}
          handlePageChange={this.handlePageChange}
          changeNumberOfItems={this.changeNumberOfItems}
          wrapperClassName="completed-reviews__pagination--dark-chevron"
          dropdownClassName="completed-reviews__pagination--dark-text"
        />
      </div>
    );
  }
}

export default Reviews;
