import React from "react";
import SupportSplash from "./support_splash";

export default function SupportTickets() {
  return (
    <div className="support">
      <div className="content">
        <div className="support__table">
          <SupportSplash />
        </div>
      </div>
    </div>
  );
}
