import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory, useLocation, Link } from "react-router-dom";
import Header from "components/reusable/header";
import { Loader } from "components/reusable/Loader";
import SmileLockAnimated from "resources/img/brand/lock_loco_animated_80x80.gif";
import SmileLock from "resources/img/brand/smile-id__symbol--96x96.svg";
import { initiateSSO, callbackSSO } from "util/api_util";

function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(props.errors);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const params = new URLSearchParams(useLocation().search);
  // "code" is a searchParam when we the user is redirected back to this page by
  // by our SSO provider.
  const [code, setCode] = useState(() => params.get("code") || null);
  const [disabled, setDisabled] = useState(false);
  // Get the history so that if after an SSO redirect, if an error occurs,
  // we can remove the code from the queryParams and allow the user to start
  // the login flow from the beginning.
  const history = useHistory();
  // Important check to avoid infinite rerenders.
  const passwordLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    // In case the user alters their email use the value from the input.
    await props.login({ email: emailInput, password });
    setLoading(false);
  };

  const onKeyDown = (e) => {
    if (disabled) {
      return;
    }
    if (e.key === "Enter") {
      if (email && password) {
        passwordLogin(e);
      }
      if (!email) {
        emailEntered(e);
      }
      if (redirectUrl) {
        followSSORedirect(e);
      }
    }
  };

  const emailEntered = (e) => {
    e.preventDefault();
    if (!emailInput) {
      return;
    }
    setEmail(emailInput);
    setLoading(true);
    setDisabled(true);
  };

  function followSSORedirect(e) {
    e.preventDefault();
    window.location = redirectUrl;
  }

  const msgStyle = classNames("message message--login", {
    "message--error": props.errors || error,
  });
  const inputStyle = classNames("input--line", { danger: props.errors });

  useEffect(() => {
    if (email && !code) {
      try {
        initiateSSO({ email }).then((resp) => {
          if (resp.error) {
            setError(resp.error);
            setDisabled(false);
            return;
          }
          setRedirectUrl(resp.redirect_url);
          setDisabled(false);
          setError(null);
          setLoading(false);
        });
      } catch (err) {
        setDisabled(false);
      }
    }
  }, [email]);

  // This is a hack to get around the potential of having this hook run multiple
  // times. The useEffect hook runs when a render is complete whether or not the
  // requirement exists. Therefore, we need a way to know if we have already
  // made this call to the backend so we can no-op.
  let callbackPromise;
  useEffect(() => {
    // If the SSO provider has redirected back our app with "code" as a searchParam
    // we need to call to the backend to verify and get the auth_token for use in
    // our system.
    if (callbackPromise) {
      return;
    }
    if (code && !error && !disabled) {
      const state = params.get("state");
      setDisabled(true);
      setLoading(true);
      callbackPromise = callbackSSO({ code, email: state.split("=")[1] });
      callbackPromise.then((resp) => {
        if (resp.error) {
          setError(resp.error);
          setCode(null);
          setDisabled(false);
          setLoading(false);
          // Remove the "code" param from the url so we can revert to the beginning of
          // the login flow.
          params.delete("code");
          history.replace({
            search: params.toString(),
          });
          callbackPromise = undefined;
        }
        if (resp.auth_token) {
          localStorage.setItem("token", resp.auth_token);
          window.location = "/";
        }
      });
    }
  }, [code]);

  if (code && !error) {
    return (
      <>
        <Header envSlider={false} login />
        <div className="newsmile-container__login">
          <div className="smile-login__container">
            <div className="smile-login__icon-container">
              <img
                className="smile-login__icon"
                src={loading ? SmileLockAnimated : SmileLock}
                alt="smile logo"
              />
            </div>
            <div className="smile-login__heading">Welcome to Smile ID</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header envSlider={false} />
      <div className="newsmile-container__login">
        <div className="smile-login__container">
          <div className="smile-login__icon-container">
            <img
              className="smile-login__icon"
              src={loading ? SmileLockAnimated : SmileLock}
              alt="smile logo"
            />
          </div>
          <div className="smile-login__heading">Welcome to Smile ID</div>
          {(error || props.errors) && (
            <div
              className={msgStyle}
              style={{ display: props.errors || error ? "block" : "none" }}
            >
              {props.errors || error}
            </div>
          )}

          <form className="smile-form smile-form--login" id="new_account">
            <div className="field">
              <br />
              <label>Email Address</label>
              <input
                autoFocus
                type="email"
                id="email"
                name="email"
                className={inputStyle}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                }}
                value={emailInput}
                onKeyDown={onKeyDown}
              />
            </div>
            {!email && (
              <div
                className="button-container--center"
                onKeyDown={onKeyDown}
                onClick={emailEntered}
              >
                <button
                  disabled={disabled || !emailInput}
                  className="btn btn-primary smile-login__button"
                  type="button"
                  data-testid="continue-button"
                >
                  {loading && (
                    <>
                      <Loader />
                      &nbsp;&nbsp;
                    </>
                  )}
                  Continue
                </button>
              </div>
            )}
            {redirectUrl && (
              <div className="login__signup-container button-container--center">
                <br />
                <button
                  disabled={disabled}
                  className="btn btn-primary smile-login__button"
                  type="button"
                  data-testid="sso-button"
                  onClick={followSSORedirect}
                >
                  {disabled && (
                    <>
                      <Loader />
                      &nbsp;&nbsp;
                    </>
                  )}
                  Sign In With SSO
                </button>
                <br />
                <p>Or</p>
                <br />
              </div>
            )}
            {email && !loading && (
              <div>
                <div className="field">
                  <br />
                  <label> Password </label>
                  <input
                    data-testid="password-input"
                    autoComplete="off"
                    type="password"
                    id="password"
                    name="password"
                    className={inputStyle}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                    onKeyDown={onKeyDown}
                  />
                </div>
                <div className="login__forgot-password-container button-container--right">
                  <Link
                    className="login__forgot-password"
                    to="/forgot_password"
                    data-testid="forgot-password-link"
                  >
                    Forgot password?
                  </Link>
                </div>
                <br />
                <div className="button-container--center" onKeyDown={onKeyDown}>
                  <button
                    onClick={passwordLogin}
                    disabled={loading}
                    className="btn btn-primary smile-login__button"
                    type="button"
                    data-testid="login-button"
                  >
                    {loading && (
                      <>
                        <Loader />
                        &nbsp;&nbsp;
                      </>
                    )}
                    Sign In
                  </button>
                </div>
              </div>
            )}
            <div className="login__signup-container button-container--center">
              <span>Don’t have an account? </span>
              <a
                className="login__signup"
                href="https://usesmileid.com/talk-to-an-expert"
              >
                Sign Up
              </a>
            </div>
            <div className="fixed-dots--bottom-across" />
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
