import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { loadPendingReviewAndKYCCount } from "actions/actions";
import Icon from "resources/img/icons/chevron.svg";
import { userHasPermission } from "util/route_util";
import { SidebarLink } from "./sidebar_link";

function AdminSidebar(props) {
  const [showSideNav, setShowSideNav] = useState(true);

  useEffect(() => {
    localStorage.removeItem("adminPartnerId");
  }, []);

  useEffect(() => {
    props.loadPendingReviewAndKYCCount();
  }, [props.displayEnvironment]);

  const toggleSideNav = () => {
    setShowSideNav((prev) => !prev);
  };

  const hasPermission = userHasPermission(["partner full_read"]);
  const environment = { false: "sandbox", true: "production" }[
    props.displayEnvironment
  ];

  return (
    <>
      <button
        aria-expanded={showSideNav}
        className="btn icon-btn sidebar__toggle"
        type="button"
        onClick={toggleSideNav}
      >
        <span className="visually-hidden">Toggle Navigation</span>
        <img alt="toggle" src={Icon} />
      </button>
      <div className={`container__sidebar ${showSideNav ? "" : "hidden"}`}>
        <div className="sidebar__nav-elements">
          <SidebarLink
            allowed={hasPermission}
            count={props.kycPending}
            to="/admin/kyc_reviews"
          >
            Review KYC
          </SidebarLink>
          <SidebarLink allowed={hasPermission} to="/admin/access_logs">
            Access Logs
          </SidebarLink>
          <SidebarLink to="/admin/reviews" count={props.pending}>
            Open Reviews
          </SidebarLink>
          <SidebarLink to="/admin/reviews/completed">
            Completed Reviews
          </SidebarLink>
          <SidebarLink to="/admin/docv_reviews">
            Document Verification Reviews
          </SidebarLink>
          <SidebarLink to="/admin/spoof_reviews">Spoof Reviews</SidebarLink>
          <SidebarLink to="/admin/id_card_reviews">ID Card Reviews</SidebarLink>
          <SidebarLink to="/admin/compare_reviews">Compare Reviews</SidebarLink>
          <SidebarLink to="/admin/companies" allowed={hasPermission}>
            Manage Partners
          </SidebarLink>
          <SidebarLink to="/admin/partner_dashboards" allowed={hasPermission}>
            View Partner Portal
          </SidebarLink>
          <SidebarLink
            allowed={hasPermission}
            to={`/admin/${environment}/smile_links`}
          >
            Smile Links
          </SidebarLink>
          <SidebarLink to="/admin/web_app">SmartCheck&trade;</SidebarLink>
          <SidebarLink to="/admin/analytics" allowed={hasPermission}>
            Analytics
          </SidebarLink>
          <SidebarLink to="/admin/id_status" allowed={hasPermission}>
            ID API Status{" "}
          </SidebarLink>
          <SidebarLink to="/admin/queue_health" allowed={hasPermission}>
            Queue Health
          </SidebarLink>
          <SidebarLink to="/partner/account_settings" allowed={hasPermission}>
            Account Settings
          </SidebarLink>
          <div className="sidebar__nav-element-container">
            {hasPermission ? (
              <a
                className="sidebar__nav-element"
                href="https://docs.usesmileid.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a>
            ) : null}
          </div>
          <SidebarLink to="/admin/permissions" allowed={hasPermission}>
            Permissions
          </SidebarLink>
          <SidebarLink to="/admin/reviewer_permissions" allowed={hasPermission}>
            Reviewer Permissions
          </SidebarLink>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  pending: state.pending.reviewsPending,
  kycPending: state.pending.kycPending,
});

const mapDispatchToProps = (dispatch) => ({
  loadPendingReviewAndKYCCount: () => loadPendingReviewAndKYCCount(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebar);
