import React, { Component } from "react";
import Carousel from "./carousel";
import ReviewImage from "./review_image";

const getImageLabel = (imageURL) => {
  if (imageURL.includes("Preview")) return "Selfie";
  if (imageURL.includes("ID_Photo")) return "ID Authority Photo";
  if (imageURL.includes("IDCard")) return "ID Card";

  return "Liveness Check Photo";
};

class SpoofReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      fraud: null,
      fraudType: null,
      submitted: false,
    };
    this.handleRadio = this.handleRadio.bind(this);
    this.handleFraud = this.handleFraud.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.back = this.back.bind(this);
    this.next = this.next.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      counter: props.counter,
    });
  }

  back(e) {
    e.preventDefault();
    this.props.undo();
  }

  next(e) {
    e.preventDefault();
    this.props.next();
  }

  handleRadio(event) {
    this.setState({
      fraud: event.target.value,
    });
  }

  handleFraud(event) {
    this.setState({
      fraudType: event.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
    const data = {
      id: this.props.review.id,
      fraud: this.state.fraud === "yes",
      image_unusable: this.state.fraud === "image_unusable",
      fraud_type: this.state.fraudType,
    };
    if (this.props.endpoint) {
      this.props.endpoint(data).then((resp) => {
        this.props.callback(resp, this.props.review.id);
        if (this.props.countdown) {
          this.props.countdown();
        }
      });
    } else {
      this.props.submitReview(data).then((resp) => {
        this.setState({
          active: 0,
          fraud: null,
          fraudType: null,
        });
        if (this.props.callback) {
          this.props.callback(resp, this.props.review.id);
        }
        if (this.props.countdown) {
          this.props.countdown();
        }
      });
    }
  }

  render() {
    let key = 0;
    if (!this.props.review) {
      return;
    }
    const carousel = [];
    ["source", "service", "targets"].forEach((image) => {
      if (typeof this.props.review[image] === "string") {
        carousel.push(
          <ReviewImage
            jobID={this.props.review.job.job_id}
            key={key}
            label={getImageLabel(this.props.review[image])}
            source={this.props.review[image]}
            variant="spoof"
            left={image === "source"}
          />,
        );
        key += 1;
      } else if (this.props.review[image]) {
        this.props.review[image].forEach((imageLink, index) => {
          carousel.push(
            <ReviewImage
              jobID={this.props.review.job.job_id}
              key={key}
              label={getImageLabel(imageLink)}
              source={imageLink}
              variant="spoof"
              left={index % 2 !== 0}
            />,
          );
          key += 1;
        });
      }
    });

    let id_card_front;
    let id_card_rear;
    if (this.props.review.id_card_front) {
      id_card_front = (
        <img
          src={this.props.review.id_card_front}
          alt="id front"
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      );
    }
    if (this.props.review.id_card_rear) {
      id_card_rear = (
        <img
          src={this.props.review.id_card_rear}
          alt="id back"
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      );
    }

    let fraudForm;
    let idFocus;
    if (this.state.fraud === "yes") {
      let fraudFocus = " action--focus";
      if (this.state.fraudType) {
        fraudFocus = " action--nofocus";
      }
      fraudForm = (
        <div className={`block--sub-decision${fraudFocus}`}>
          <h2>what type?</h2>
          <div className="block-option multi">
            <input
              type="radio"
              name="radiogroup2"
              id="type1"
              value="video"
              checked={this.state.fraudType === "video"}
              onChange={this.handleFraud}
            />
            <label htmlFor="type1" className="btn-default btn-1 fraud-radio">
              Video
            </label>
            <input
              type="radio"
              name="radiogroup2"
              id="type2"
              value="photo"
              checked={this.state.fraudType === "photo"}
              onChange={this.handleFraud}
            />
            <label htmlFor="type2" className="btn-default btn-2 fraud-radio">
              Photo
            </label>
            <input
              type="radio"
              name="radiogroup2"
              id="type3"
              value="other"
              checked={this.state.fraudType === "other"}
              onChange={this.handleFraud}
            />
            <label htmlFor="type3" className="btn-default btn-3 fraud-radio">
              Other
            </label>
          </div>
        </div>
      );
    }
    let submitFocus = "not-active";
    if (
      ["no", "image_unusable"].includes(this.state.fraud) ||
      (this.state.fraud === "yes" && this.state.fraudType)
    ) {
      submitFocus = "active";
    }

    if (this.state.fraud) {
      idFocus = " action--nofocus";
    }

    let spoofForm;

    if (
      this.props.completed &&
      this.props.review.smile_reference !== undefined
    ) {
      let fraudType;
      if (
        this.props.review.smile_reference.internal.Payload.FraudType !== "null"
      ) {
        fraudType = `Fraud type: ${this.props.review.smile_reference.internal.Payload.FraudType}`;
      }
      const fraud =
        this.props.review.smile_reference.internal.Payload.Fraud === "true";
      spoofForm = (
        <div>
          <p className={fraud ? "failure" : "success"}>
            {this.props.review.smile_reference.result.ResultText}
          </p>
          <p>{fraudType}</p>
          <button className="btn-secondary-inline" onClick={this.props.undo}>
            edit
          </button>
        </div>
      );
    } else if (this.state.submitted) {
      spoofForm = (
        <div className="spoof-form">
          <div className="block--decision action--focus">
            <div className="block-action">
              <h3>
                Thank you. The next set of images will appear in{" "}
                <span id="spoof-countdown">{this.state.counter}s</span>
              </h3>
              <button
                type="submit"
                className="btn-secondary-inline"
                onClick={this.next}
              >
                next
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      spoofForm = (
        <div className="spoof-form">
          <h1>Is this Fraud?</h1>
          <div className="block--decision decision-yes">
            <div className="block-option thumb">
              <input
                type="radio"
                name="radiogroup1"
                id="rd1"
                value="yes"
                checked={this.state.fraud === "yes"}
                onChange={this.handleRadio}
              />
              <label htmlFor="rd1" className="thumb-up">
                Yes
              </label>
              <input
                type="radio"
                name="radiogroup1"
                id="rd2"
                value="no"
                checked={this.state.fraud === "no"}
                onChange={this.handleRadio}
              />
              <label htmlFor="rd2" className="thumb-down">
                No
              </label>
            </div>
            {fraudForm}
            <div className={`block-action ${submitFocus}`}>
              <button
                className="btn-primary"
                onClick={this.handleSubmit}
                disabled={this.state.submitted}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    }

    let infoDiv;
    if (this.props.completed) {
      infoDiv = (
        <ul className="sidebar_jobid spoof-centered">
          <li>
            <span>Spoof Review</span>
          </li>
          <li>
            <label>Job id</label>
            <span id="jobid_number">{`${this.props.review.job.partner_id}-${this.props.review.job.job_id}`}</span>
          </li>
          <li>
            <label>First sent at</label>
            <span id="jobid_firstsentat">
              {
                new Date(this.props.review.first_sent_at)
                  .toLocaleString()
                  .split(",")[0]
              }
            </span>
          </li>
          <li>
            <label>Author</label>
            <span id="jobid_autor">{this.props.review.author}</span>
          </li>
        </ul>
      );
    } else {
      infoDiv = (
        <ul className="sidebar_jobid spoof-centered">
          <li>
            <span>Spoof Review</span>
          </li>
          <li>
            <label>Job id</label>
            <span id="jobid_number">{`${this.props.review.job.partner_id}-${this.props.review.job.job_id}`}</span>
          </li>
          <li>
            <label>Created at</label>
            <span id="jobid_createat">
              {
                new Date(this.props.review.created_at)
                  .toLocaleString()
                  .split(",")[0]
              }
            </span>
          </li>
          <li>
            <label>First sent at</label>
            <span id="jobid_firstsentat">
              {
                new Date(this.props.review.first_sent_at)
                  .toLocaleString()
                  .split(",")[0]
              }
            </span>
          </li>
        </ul>
      );
    }

    return (
      <section className="container__content">
        {infoDiv}
        <div className="">
          <Carousel>{carousel}</Carousel>
        </div>
        <div id="" />
        <div className="spoof-id">
          <div className={`user-id left${idFocus}`}>{id_card_front}</div>
          {spoofForm}
          <div className={`user-id right${idFocus}`}>{id_card_rear}</div>
        </div>
      </section>
    );
  }
}

export default SpoofReview;
