import React, { Component } from "react";
import moment from "moment";
import DownloadIcon from "resources/img/icons/download.svg";
import {
  asyncDownloadJobsCSV,
  asyncDownloadUsersCSV,
  buildState,
  buildUrl,
  fetchJobsForDashboard,
  fetchPartnerInfo,
  fetchUsers,
} from "util/api_util";
import { dynamicSort, formatDashboardJobs, getUserType } from "util/selectors";
import FilterJobTable from "./filter_job_table";
import { FilterModal } from "./filterModal";
import JobAndUserListTable from "./job_and_user_list_table";
import SearchJobTable from "./search_job_table";

class List extends Component {
  constructor(props) {
    super(props);
    const currentState = buildState(props.location.search);
    const path = window.location.pathname.split("/");
    const filters = [];
    if (currentState.job_type)
      filters.push({ filter: "job_type", input: currentState.job_type });
    if (currentState.result)
      filters.push({ filter: "result", input: currentState.result });

    this.state = {
      filterToggle: false,
      dateToggle: false,
      showDownloadModal: false,
      filters,
      searchTerm: currentState.search,
      sortBy: "-created_at",
      startDate:
        this.formatDateParams(props.location.search, "start_date") || undefined,
      endDate:
        this.formatDateParams(props.location.search, "end_date") || undefined,
      partnerId: props.match.params.partnerId,
      fetchingDataSearch: false,
      loading: false,
      selectorFilter: [],
      last_key: currentState.last_key,
      first_key: currentState.first_key,
      totalPages: 1,
      clickedPage: parseInt(currentState.page, 10) || 1,
      resultCount: parseInt(currentState.results_per_page, 10) || 20,
      data: [],
      userType: getUserType(),
      partnerInfo: {
        company: {},
      },
      pageName: path[path.length - 1],
      smileSecureEnabled: false,
      countries: currentState.countries
        ? currentState.countries
            .split(",")
            .map((code) => (code === "null" ? null : code))
        : [],
    };
    this.filterToggle = this.filterToggle.bind(this);
    this.onDateToggle = this.onDateToggle.bind(this);
    this.search = this.search.bind(this);
    this.onInput = this.onInput.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.onSearchInput = this.onSearchInput.bind(this);
    this.sortTableBy = this.sortTableBy.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.searchByDate = this.searchByDate.bind(this);
    this.filterDataBySelector = this.filterDataBySelector.bind(this);
    this.removeSelectors = this.removeSelectors.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.onClearFilter = this.onClearFilter.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleResultCountChange = this.handleResultCountChange.bind(this);
    this.onCountriesSelected = this.onCountriesSelected.bind(this);
  }

  formatDateParams(params, type) {
    if (buildState(params)[type]) {
      const date = buildState(params)[type];
      return new Date(date);
    }
  }

  componentDidMount() {
    this.setupRequest();
    const json = {};
    if (this.state.userType === "admin") {
      json.partner = this.state.partnerId;
    }
    fetchPartnerInfo(json).then((resp) => {
      this.setState({
        partnerInfo: resp.partner,
      });
    });
  }

  componentDidUpdate(props) {
    if (props.displayEnvironment !== this.props.displayEnvironment) {
      this.setupRequest();
    }
  }

  getData(request) {
    if (this.state.pageName === "user_list") {
      fetchUsers(request).then((resp) => {
        this.setState({
          data: resp.users,
          loading: false,
          totalPages: resp.pagination.nextPage,
          page: resp.pagination.page,
          smileSecureEnabled: resp.smile_secure_enabled,
        });
        if (this.state.dates) {
          this.setInitialDates();
        }
      });
    } else {
      fetchJobsForDashboard(request)
        .then((resp) => {
          this.setState({
            data: formatDashboardJobs(resp.jobs, resp.result_codes),
            loading: false,
            totalPages: resp.pagination.nextPage,
            page: resp.pagination.page,
          });
          if (this.state.dates) {
            this.setInitialDates();
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            errors: err.message,
          });
        });
    }
  }

  configureRequest({
    page = 1,
    results_per_page = 20,
    start_date,
    end_date,
    countries,
  }) {
    let request = {
      page,
      results_per_page,
      start_date,
      end_date,
      env: this.getEnv(),
      ...(countries && countries.length > 0
        ? {
            countries: countries
              .map((value) => (value === null ? "null" : value))
              .join(","),
          }
        : {}),
    };

    if (this.state.userType === "admin") {
      request.partner_id = this.state.partnerId;
    }
    if (this.state.searchTerm) {
      request.search = this.state.searchTerm;
    }

    if (this.state && this.state.data && this.state.data.length > 0) {
      const { data } = this.state;
      if (this.state.clickedPage > this.state.page) {
        request.last_key = data[data.length - 1].id;
      } else if (this.state.clickedPage < this.state.page) {
        request.first_key = data[0].id;
      }
    } else if (this.state.last_key) {
      request.last_key = this.state.last_key;
    } else if (this.state.first_key) {
      request.first_key = this.state.first_key;
    }
    const filterRequest = {};
    if (this.state.filters) {
      this.state.filters.forEach((filter) => {
        const key = filter.filter;
        filterRequest[key] = filter.input;
      });
    }

    request = { ...request, ...filterRequest };
    return request;
  }

  getNewData(request, userType, partnerId) {
    this.setState({ loading: true });

    if (userType === "admin") {
      this.getData(request);

      this.amendUrl(`/admin/partner_dashboards/${partnerId}`, request);
      // TODO: can take out session storage
      sessionStorage.setItem(
        "url",
        `/partner_dashboards/${partnerId}/${this.state.pageName}`,
      );
    } else {
      this.getData(request);
      this.amendUrl("/partner", request);
      sessionStorage.setItem("url", `/partner/${this.state.pageName}`);
    }
  }

  setupRequest() {
    const request = this.configureRequest({
      page: this.state.clickedPage,
      results_per_page: this.state.resultCount,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      countries: this.state.countries,
    });
    this.getNewData(
      request,
      this.state.userType,
      this.props.match.params.partnerId,
    );
  }

  handlePageChange(page) {
    this.setState(
      {
        clickedPage: page,
      },
      () => {
        this.setupRequest();
      },
    );
  }

  handleResultCountChange(resultCount) {
    this.setState(
      {
        resultCount,
      },
      () => {
        this.setupRequest();
      },
    );
  }

  amendUrl(baseUrl, request) {
    this.props.history.push(
      `${baseUrl}${buildUrl(this.state.pageName, request)}`,
    );
  }

  setInitialDates() {
    const start = this.state.dates.start_date;
    const end = this.state.dates.end_date;
    this.setState({
      startDate: start ? moment(start) : undefined,
      endDate: end ? moment(end) : undefined,
    });
  }

  filterToggle() {
    const newToggleState = !this.state.filterToggle;
    this.setState({
      filterToggle: newToggleState,
    });
  }

  onDateToggle() {
    const newToggleState = !this.state.dateToggle;
    this.setState({
      dateToggle: newToggleState,
    });
  }

  onSearchInput(e) {
    this.setState({
      searchTerm: e.target.value,
    });
  }

  handleDateChange(key, date) {
    this.setState({
      [key]: date,
    });
  }

  searchByDate() {
    // TODO: check where we use fetching state
    const newFetchingState = !this.state.fetchingDataSearch;
    this.setState({ fetchingDataSearch: newFetchingState });
    const request = this.configureRequest({
      page: 1,
      results_per_page: this.state.resultCount,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      countries: this.state.countries,
    });

    this.getNewData(request, this.state.userType, this.state.partnerId);
    this.onDateToggle();
  }

  search(e) {
    e.preventDefault();
    const newFetchingState = !this.state.fetchingDataSearch;
    this.setState({ fetchingDataSearch: newFetchingState });
    const request = this.configureRequest({
      page: 1,
      results_per_page: this.state.resultCount,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      countries: this.state.countries,
    });
    request.search = this.state.searchTerm;

    this.getNewData(request, this.state.userType, this.state.partnerId);
  }

  clearSearch() {
    this.setState({ searchTerm: "" }, () => {
      this.setupRequest();
    });
  }

  onClearFilter() {
    this.setState(
      {
        fetchingDataSearch: !this.state.fetchingDataSearch,
        startDate: undefined,
        endDate: undefined,
        results_per_page: 20,
        page: 1,
        selectorFilter: [],
        filters: [],
        loading: false,
        last_key: undefined,
        first_key: undefined,
        searchTerm: undefined,
        countries: [],
      },
      () => {
        // this was in the then, now its out
        const request = this.configureRequest({});
        this.getNewData(request, this.state.userType, this.state.partnerId);
      },
    );
  }

  changeFilter(filterType, filterValue) {
    const { filters } = this.state;
    const index = filters.findIndex(
      (filterElement) => filterElement.filter === filterType,
    );

    if (index === -1) {
      filters.push({ filter: filterType, input: filterValue });
    } else {
      filters[index].input = filterValue;
    }

    if (filterValue === "All Results" || filterValue === "All Job Types") {
      filters.splice(index);
    }

    this.setState({
      filters,
      selectorFilter: filters,
    });

    if (filters) {
      this.requestFilterChanges();
    }
  }

  onCountriesSelected(countries) {
    this.setState(
      {
        countries: countries.map((country) => country.value),
      },
      () => {
        this.setupRequest();
      },
    );
  }

  requestFilterChanges() {
    const newFetchingState = !this.state.fetchingDataSearch;
    this.setState({ fetchingDataSearch: newFetchingState });
    const request = this.configureRequest({
      results_per_page: this.state.resultCount,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      countries: this.state.countries,
    });

    this.getNewData(
      request,
      this.state.userType,
      this.props.match.params.partnerId,
    );
  }

  sortTableBy(sortBy) {
    if (sortBy === this.state.sortBy) {
      sortBy = `-${sortBy}`;
    }
    this.setState({
      sortBy,
    });
  }

  getEnv() {
    return {
      false: "test",
      true: "production",
    }[this.props.displayEnvironment];
  }

  onInput(e) {
    e.preventDefault();

    const idx = parseInt(e.target.id, 10);
    const { filters } = this.state;
    const modifyFilter = filters.splice(idx, 1)[0];

    modifyFilter.input = e.target.value;
    filters.splice(idx, 0, modifyFilter);

    this.setState({
      filters,
    });
  }

  filterDataBySelector(data, selectorArray) {
    let filteredData = data;
    for (let i = 0; i < selectorArray.length; i += 1) {
      const selector = selectorArray[i];
      if (!selector.filter || selector.input === "All") {
        return data;
      }
      const key = selector.filter;
      const val = selector.input;
      filteredData = filteredData.filter((job) => job[key] === val);
    }
    // if empty
    return filteredData;
  }

  removeSelectors() {
    this.setState({
      selectorFilter: [],
      filters: [],
    });
  }

  renderJobTable() {
    if (this.state.loading) {
      return (
        <div className="loader__container">
          <div className="loader loader--centered" />
        </div>
      );
    }
    if (this.state.data) {
      const allData = this.state.data;
      const sorted = allData.sort(dynamicSort(this.state.sortBy));
      if (allData.length > 0) {
        return (
          <JobAndUserListTable
            history={this.props.history}
            data={sorted}
            sortTableBy={this.sortTableBy}
            sortBy={this.state.sortBy}
            userType={this.state.userType}
            partnerId={this.state.partnerId}
            totalPages={this.state.totalPages}
            activePage={this.state.clickedPage}
            pageChange={this.handlePageChange}
            resultCount={this.state.resultCount}
            resultCountChange={this.handleResultCountChange}
            pageName={this.state.pageName}
            smileSecureEnabled={this.state.smileSecureEnabled}
          />
        );
      }
      return (
        <div className="joblist__no-data">
          There is no data available for your selected dates and filters.
        </div>
      );
    }
  }

  render() {
    return (
      <div className="joblist">
        <SearchJobTable
          history={this.props.history}
          filterToggleFunc={this.filterToggle}
          filterToggle={this.state.filterToggle}
          search={this.search}
          searchTerm={this.state.searchTerm}
          onSearchInput={this.onSearchInput}
          partnerId={this.state.partnerId}
          clearSearch={this.clearSearch}
          loading={this.state.fetchingDataSearch}
          placeholder={
            this.state.pageName === "job_list"
              ? "Search by Job ID or User ID"
              : "Search by User ID"
          }
        />

        <FilterJobTable
          filterToggle={this.state.filterToggle}
          filters={this.state.filters}
          changeFilter={this.changeFilter}
          onInput={this.onInput}
          sortingStats={{}}
          removeSelectors={this.removeSelectors}
          onClearFilter={this.onClearFilter}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          searchByDate={this.searchByDate}
          handleDateChange={this.handleDateChange}
          fetchingData={this.state.fetchingDataSearch}
          userType={this.state.userType}
          dateToggle={this.state.dateToggle}
          onDateToggle={this.onDateToggle}
          pageName={this.state.pageName}
          smileSecureEnabled={this.state.smileSecureEnabled}
          selectedCountries={this.state.countries}
          onCountriesSelected={this.onCountriesSelected}
        />
        {this.state.pageName === "job_list" && (
          <>
            <div className="joblist__header">
              <div className="title">
                <h3>Job List</h3>
                <span>Keep track of jobs and their KYC results.</span>
              </div>
              <button
                onClick={() => this.setState({ showDownloadModal: true })}
                className="joblist__export-btn"
              >
                <img src={DownloadIcon} alt="async download job list" />
                &nbsp;&nbsp;Export CSV
              </button>
            </div>
            <FilterModal
              show={this.state.showDownloadModal}
              onClick={async ({ startDate, endDate }) => {
                const request = this.configureRequest({
                  results_per_page: this.state.resultCount,
                  start_date: startDate,
                  end_date: endDate,
                  countries: this.state.countries,
                });
                const data = await asyncDownloadJobsCSV(request);
                if (!data.success) throw new Error(data.error);
              }}
              onClose={() => this.setState({ showDownloadModal: false })}
            />
          </>
        )}
        {this.state.pageName === "user_list" && (
          <>
            <div className="joblist__header">
              <div className="title">
                <h3>User List</h3>
              </div>
              <button
                onClick={() => this.setState({ showDownloadModal: true })}
                className="joblist__export-btn"
              >
                <img src={DownloadIcon} alt="async download user list" />
                &nbsp;&nbsp;Export CSV
              </button>
            </div>
            <FilterModal
              show={this.state.showDownloadModal}
              onClick={async ({ startDate, endDate }) => {
                const request = this.configureRequest({
                  results_per_page: this.state.resultCount,
                  start_date: startDate,
                  end_date: endDate,
                  countries: this.state.countries,
                });
                const data = await asyncDownloadUsersCSV(request);
                if (!data.success) throw new Error(data.error);
              }}
              onClose={() => this.setState({ showDownloadModal: false })}
            />
          </>
        )}
        {this.renderJobTable()}
      </div>
    );
  }
}

// the search term will not persist on reload / we'd need to set dates to null if it does
// all filters except the date will not persist either
export default List;
