import React from "react";
import Checkmark from "resources/img/icons/blue-filled-checkmark.svg";
import "./styles.scss";

function ToggleButton({ pressed, onClick, children }) {
  return (
    <button
      type="button"
      className="toggle-button"
      onClick={onClick}
      aria-pressed={pressed}
    >
      {pressed && <img src={Checkmark} alt="" />}
      {children}
    </button>
  );
}

ToggleButton.defaultProps = {
  pressed: false,
};

export default ToggleButton;
