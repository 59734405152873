import React from "react";
import { createPortal } from "react-dom";
import CloseIcon from "resources/img/icons/close-icon.svg";
import { css } from "util/format_helpers";
import Modal from "../../modal/modal";
import BackButton from "./BackButton";
import styles from "./modal_wrapper.module.css";

export default function ModalWrapper({
  children,
  title,
  description,
  isOpen,
  hideBackButton,
  hideNav,
  onClose,
  onBackPressed,
  titleClass,
  icon,
  titleStyle,
}) {
  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className={styles.container}>
      <Modal show={isOpen}>
        <div className={styles.wrapper}>
          {!hideNav && (
            <div className={css("flex", styles.navigation)}>
              {!hideBackButton && (
                <BackButton
                  style={{ flexFlow: "nowrap" }}
                  onClick={onBackPressed}
                />
              )}
              <div />
              <button
                className={styles.close}
                onClick={onClose}
                data-testid="modal-x"
              >
                <img src={CloseIcon} alt="" />
                <span className="visually-hidden">Close Modal</span>
              </button>
            </div>
          )}
          <div className={styles.header}>
            <div
              style={{ titleStyle }}
              className={css(styles.title, titleClass, "h2")}
            >
              {icon && <div>{icon}</div>}
              {title}
            </div>
            <div>{description}</div>
          </div>

          {children}
        </div>
      </Modal>
    </div>,
    document.body,
  );
}
