import React from "react";
import classNames from "classnames";
import ErrorsControl from "components/reusable/CompareReview/ErrorsControl";
import QualityControl from "components/reusable/CompareReview/QualityControl";
import ReviewImage from "components/reusable/review_image";

export const getImageLabel = (imageUrl) => {
  if (imageUrl.includes("Preview")) return "Selfie";
  if (imageUrl.includes("ID_Photo")) return "ID Authority Photo";
  if (imageUrl.includes("IDCard_Back")) return "Back of ID Card";
  if (imageUrl.includes("IDCard")) return "Front of ID Card";

  return "Liveness Check Photo";
};

function CompareReviewImage({
  isControlGood,
  setIsControlGood,
  errors,
  setErrors,

  qualityName,
  errorsName,
  completed,
  left,
  imageUrls = undefined,
  className = undefined,
  images = undefined,
}) {
  const divClassName = classNames({ [className]: className });

  const displayImages = images
    ? images.map((image) => ({
        id: image.id,
        label: image.imageType.label,
        url: image.url,
      }))
    : imageUrls.map((url, idx) => ({
        url,
        id: `${url}-${idx}`,
        label: getImageLabel(url),
      }));

  return (
    <div className={divClassName}>
      {displayImages.map((img) => (
        <div
          data-testid="review-image"
          className="block--decision"
          key={img.id}
        >
          <ReviewImage label={img.label} left={left} source={img.url} />
        </div>
      ))}
      {!completed && (
        <QualityControl
          name={qualityName}
          isControlGood={isControlGood}
          setControl={setIsControlGood}
        />
      )}
      {isControlGood === false && (
        <ErrorsControl
          name={errorsName}
          errors={errors}
          setSource={setErrors}
        />
      )}
    </div>
  );
}

export default CompareReviewImage;
