import * as APIUtil from "util/api_util";

export const RECEIVE_TOKEN = "RECEIVE_TOKEN";

export const receiveToken = (token) => ({
  type: RECEIVE_TOKEN,
  token,
});

export const login = (loginInfo, dispatch) =>
  APIUtil.login(loginInfo).then((token) => dispatch(receiveToken(token)));

export const logout = (currentUser, dispatch) =>
  APIUtil.logout(currentUser).then((token) => dispatch(receiveToken(token)));
