import { connect } from "react-redux";
import { login } from "actions/session_actions";
import { checkEnrollmentStatus } from "util/api_util";
import LoginPage from "./login";

const mapStateToProps = (state) => ({
  errors: state.session.errors,
});
const mapDispatchToProps = (dispatch) => ({
  login: (loginInfo) => login(loginInfo, dispatch),
  checkEnrollmentStatus: (email) => checkEnrollmentStatus(email),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
