import { connect } from "react-redux";

import { removeEnterpriseState } from "actions/actions";
import { logout } from "actions/session_actions";
import Header from "components/reusable/header";

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  logout: (currentUser) => logout(currentUser, dispatch),
  removeEnterpriseState: () => removeEnterpriseState(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
