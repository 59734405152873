import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Link, useHistory } from "react-router-dom";
import ESignatureDetail from "components/reusable/esignature/e-signature-details";
import JobResultCard from "components/reusable/JobResultCard";
import AmlResults from "pages/aml/aml-results";
import errorWarning from "resources/img/icons/error-warning.svg";
import { fetchSmileResults } from "util/api_util";
import { JobTypes } from "util/constants";
import { getUserType } from "util/selectors";

function JobHeader({ environment, jobId, redirectBack, userType }) {
  return (
    <div className="breadcrumbs">
      <a
        className="breadcrumb-link breadcrumb-link--inactive"
        onClick={redirectBack}
      >
        Job List
      </a>
      <span> &gt; </span>
      <span>Result</span>
      {userType === "admin" && jobId && (
        <span>
          <Link
            to={`/admin/job/${environment}/${jobId}`}
            className="breadcrumb-link breadcrumb-link--inactive right"
          >
            Admin View
          </Link>
        </span>
      )}
    </div>
  );
}

function JobDetails({
  result,
  actions,
  idInfo,
  images,
  resultCodes,
  resultTitle,
  metadata,
}) {
  const jobType = parseInt(result.ran_as_job_type, 10);

  if (jobType === JobTypes.E_SIGNATURE) {
    return <ESignatureDetail jobResult={result} />;
  }
  if (jobType === JobTypes.AML) {
    return <AmlResults jobResult={result} />;
  }
  return (
    <JobResultCard
      actionsMap={actions}
      idInfo={idInfo}
      images={images}
      metadata={metadata}
      resultCodes={resultCodes}
      resultPayload={result}
      resultTitle={resultTitle}
    />
  );
}

function JobResult(props) {
  const { params } = props.match;
  const [environment, setEnvironment] = React.useState(
    () => params.environment,
  );

  const userType = getUserType();
  const history = useHistory();
  const { id, partnerId } = params;

  const { isPending, data, error } = useQuery({
    queryKey: ["fetchSmileResults", id, environment, partnerId],
    queryFn: async () => {
      sessionStorage.setItem(
        "displayEnvironment",
        environment === "production",
      );
      const response = await fetchSmileResults(
        `smile_results/${id}`,
        userType === "admin" ? { partner_id: partnerId } : undefined,
      );
      const {
        actions,
        error,
        id_info,
        images,
        metadata,
        result_codes: resultCodes,
        result_title: resultTitle,
        results,
      } = response;

      if (error) {
        throw new Error(error);
      }

      let newIdInfo = {};
      let result = {};

      if (results?.length > 0) {
        const mostRecentResult = results.reduce((acc, cur) =>
          new Date(cur.smile_reference_created_at) >
          new Date(acc.smile_reference_created_at)
            ? cur
            : acc,
        );

        result = mostRecentResult;
        newIdInfo =
          {
            1: id_info,
            5: mostRecentResult,
            6: mostRecentResult.document_info,
            7: mostRecentResult,
            11: mostRecentResult,
          }[mostRecentResult.ran_as_job_type] || {};
      }

      return {
        actions,
        idInfo: newIdInfo,
        images,
        metadata,
        result,
        resultTitle,
        resultCodes,
      };
    },
    retry: 5,
  });

  React.useEffect(() => {
    const { id, environment, partnerId } = params;
    setEnvironment(environment);
    sessionStorage.setItem(
      "displayEnvironment",
      environment === "production" ? "true" : "false",
    );

    const basePath =
      userType === "admin"
        ? `admin/${partnerId}/job_results`
        : `/partner/job_results`;

    const url = `${basePath}/${environment}/${id}`;
    sessionStorage.setItem("url", url);
  }, [params]);

  const redirectBack = () => {
    const url =
      userType === "admin"
        ? `/admin/partner_dashboards/${partnerId}/job_list`
        : "/partner/job_list";
    history.push(url);
  };

  const {
    result,
    actions,
    idInfo,
    images,
    resultCodes,
    metadata,
    resultTitle,
  } = data ?? {};

  return (
    <>
      <JobHeader
        environment={environment}
        jobId={data?.result?.job_id}
        redirectBack={redirectBack}
        userType={userType}
      />
      {error && (
        <div className="no-info__container">
          <img className="icon--large" src={errorWarning} alt="Error" />
          <div className="no-info">{error.message}</div>
        </div>
      )}
      {!error && !isPending && (
        <JobDetails
          actions={actions}
          idInfo={idInfo}
          images={images}
          metadata={metadata}
          result={result}
          resultCodes={resultCodes}
          resultTitle={resultTitle}
        />
      )}
      {isPending && (
        <div className="loader__container">
          <div className="loader loader--centered" />
        </div>
      )}
    </>
  );
}

export default JobResult;
