import React from "react";
import { useSelector } from "react-redux";
import IconNIMC from "resources/img/id_provider_logos/NG/NG_NATIONAL_ID.svg";
import NinV2Form from "./nin-v2-form";
import styles from "./nin-v2-form.module.css";
import NINV2UnderReview from "./nin-v2-under-review";

function NINV2() {
  const ninState = useSelector(
    ({ enterprise }) => enterprise?.partnerInfo?.company?.nin_state,
  );

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const onSubmitted = () => {
    setIsSubmitted(true);
  };

  const successStates = ["accepted", "submitted", "under_review"];

  return (
    <div>
      <div className={styles.header}>
        <img src={IconNIMC} alt="nimc logo" />
        <h1>Activate NIN Tokenization</h1>
      </div>
      {successStates.includes(ninState) || isSubmitted ? (
        <NINV2UnderReview />
      ) : (
        <NinV2Form onSubmitted={onSubmitted} />
      )}
    </div>
  );
}

export default NINV2;
