import React, { useState, memo, MouseEvent } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

interface CardProps {
  buttonText?: string;
  cardClass?: string;
  dataTooltip?: string;
  disabled?: boolean;
  heading?: string;
  href?: string;
  icon?: string;
  onCardClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  openInNewTab?: boolean;
  redirectValue?: string;
  selector?: React.ReactNode;
  subText?: string;
  text?: string;
}

const Card: React.FC<CardProps> = ({
  buttonText,
  cardClass = "",
  dataTooltip,
  disabled = false,
  heading,
  href,
  icon,
  onCardClick,
  openInNewTab = false,
  redirectValue,
  selector,
  subText,
  text,
}) => {
  const [open, setOpen] = useState(false);

  const toggleTooltip = () => setOpen(!open);

  return (
    <div
      className={classNames("smile-card", cardClass)}
      data-testid="card-container"
    >
      {selector}
      <div className="smile-card__inner-container">
        {icon && (
          <img
            className="icon--large"
            src={icon}
            alt={heading || "icon"}
            data-testid="card-icon"
          />
        )}
        {heading && (
          <div
            className="smile-card__header newsmile-heading-h2"
            data-testid="card-heading"
          >
            {heading}
          </div>
        )}
        <div>
          {text && <div data-testid="card-text">{text}</div>}
          {dataTooltip && (
            <div
              onMouseEnter={toggleTooltip}
              onMouseLeave={toggleTooltip}
              className="smile-card__tooltip"
              data-testid="card-tooltip-trigger"
            >
              <span aria-hidden="true">?</span>
              <div
                className={classNames("tooltip__content", { hidden: !open })}
                data-testid="card-tooltip-content"
                aria-label={dataTooltip}
              >
                {open && dataTooltip}
              </div>
            </div>
          )}
        </div>
        {subText && (
          <div className="newsmile-subtext" data-testid="card-subtext">
            {subText}
          </div>
        )}
      </div>
      {buttonText && href ? (
        <CardLink
          className="btn-href smile-card__button-href"
          href={href}
          target={openInNewTab ? "_blank" : "_self"}
          aria-label={buttonText}
        >
          <button
            className="btn btn-primary smile-card__button"
            disabled={disabled}
            data-testid="card-button"
            type="button"
          >
            {buttonText}
          </button>
        </CardLink>
      ) : buttonText ? (
        <div className="btn-href smile-card__button-href">
          <button
            className="btn btn-primary smile-card__button"
            type="button"
            data-value={redirectValue}
            value={redirectValue}
            onClick={onCardClick}
            disabled={disabled}
            data-testid="card-button"
            aria-label={buttonText}
          >
            {buttonText}
          </button>
        </div>
      ) : null}
    </div>
  );
};

interface CardLinkProps {
  href: string;
  target?: "_blank" | "_self";
  children: React.ReactNode;
  className?: string;
}

const CardLink = memo<CardLinkProps>(
  ({ href, target = "_self", children, className = "" }) => {
    const isExternalLink = /^https?:\/\//.test(href);
    return isExternalLink ? (
      <a
        className={className}
        href={href}
        target={target}
        rel="noopener noreferrer"
        data-testid="card-link"
      >
        {children}
      </a>
    ) : (
      <Link
        className={className}
        to={href}
        target={target}
        data-testid="card-link"
      >
        {children}
      </Link>
    );
  },
);
CardLink.displayName = "CardLink";

export default Card;
export { CardLink };
