import "components/reusable/DocumentVerificationReview/DocumentVerificationReview.scss";
import "typography.scss";

import React, { useLayoutEffect, useRef } from "react";

import { format, parseJSON } from "date-fns";
import DocumentImageViewer from "components/reusable/DocumentVerificationReview/DocumentImageViewer";
import DocumentReviewForm from "components/reusable/DocumentVerificationReview/DocumentReviewForm";
import { getEnvironment } from "contexts/displayEnvironment";

const DocVReview = ({ review, ...props }) => {
  const smileReference = review.smile_reference;
  const ref = useRef(null);

  const { source, target } = review.images || {};
  const frontIdImage = source?.url || review.source;
  const backIdImage =
    target?.find(({ imageType }) => imageType.valueText === "id_card_back")
      ?.url || review.target;

  useLayoutEffect(() => {
    if (ref.current && !props.completed) {
      ref.current?.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  }, []);

  return (
    <div
      className="container__content document_review-content dark_bg"
      ref={ref}
    >
      <div className="document_review-header">
        {!props.completed && (
          <section className="document_review_header-summary new-typography">
            <div>
              <h4 className="h4">Job ID:</h4>
              <div className="h4">
                <a
                  href={`/admin/job/${getEnvironment()}/${review.job_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {review.job.job_id}
                </a>
              </div>
            </div>
            <div>
              <h4 className="h4">Partner ID:</h4>
              <div className="h4">{review.job.partner_id}</div>
            </div>
            <div>
              <h4 className="h4">First Sent Date:</h4>
              <div className="h4">
                {format(parseJSON(review.created_at), "d MMM, yyyy hh:mm a")}
              </div>
            </div>
          </section>
        )}
        {props.completed && (
          <ul className="sidebar_jobid">
            <li>
              <span>Document Review</span>
            </li>
            <li>
              <label>Job id</label>
              <span id="jobid_number">{`${review.job.partner_id}-${review.job.job_id}`}</span>
            </li>
            <li>
              <label>First sent at</label>
              <span id="jobid_firstsentat">
                {new Date(review.first_sent_at).toLocaleString()}
              </span>
            </li>
            <li>
              <label>Author</label>
              <span id="jobid_autor">{review.author}</span>
            </li>
          </ul>
        )}
      </div>
      <div className="review--id-card">
        <div className="documents_container">
          <section className="document_front">
            <DocumentImageViewer
              src={frontIdImage}
              imageType="id_card"
              enableBoundingBox
            />
          </section>
          {!backIdImage && (
            <section className="h1 no_document_back">No back of ID</section>
          )}
          {backIdImage && (
            <section className="document_back">
              <DocumentImageViewer
                src={backIdImage}
                imageType="id_card_back"
                enableBoundingBox
              />
            </section>
          )}
        </div>
        <div className="review_forms_container">
          <DocumentReviewForm
            rejectionReason={smileReference?.internal?.Payload?.RejectionReason}
            review={review}
            completed={props.completed}
            {...props}
          />
        </div>
      </div>
      <ConfidenceResult
        completed={props.completed}
        result={smileReference?.result}
      />
    </div>
  );
};

const ConfidenceResult = ({ completed, result }) => {
  if (!completed) return null;

  return (
    <div className="block--decision">
      {result && result.ConfidenceValue && (
        <p className={result.ConfidenceValue === "100" ? "success" : "failure"}>
          {result.ResultText}
        </p>
      )}
    </div>
  );
};

export default DocVReview;
