import React from "react";

function QualityControl({ name, isControlGood, setControl }) {
  const id = React.useId();
  const options = [
    {
      value: true,
      label: "Good Photo",
      labelClass: "thumb-up",
      checked: isControlGood === true,
      onChange: (e) => setControl(e.target.value === "true"),
    },
    {
      value: false,
      label: "Bad Photo",
      labelClass: "thumb-down",
      checked: isControlGood === false,
      onChange: (e) => setControl(e.target.value === "true"),
    },
  ];

  return (
    <div data-testid="quality-control" className="block-option thumb">
      {options.map((option, index) => (
        <React.Fragment key={`rd${index + 1}-${id}`}>
          <input
            type="radio"
            id={`rd${index + 1}-${id}`}
            value={option.value}
            name={name}
            checked={option.checked}
            onChange={option.onChange}
          />
          <label htmlFor={`rd${index + 1}-${id}`} className={option.labelClass}>
            {option.label}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
}

export default QualityControl;
