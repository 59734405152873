import { connect } from "react-redux";

import { fetchResources } from "actions/actions";
import TableHeader from "components/reusable/TableHeader";
import { decode } from "util/route_util";

const mapStateToProps = (state) => ({
  pagination: state.pagination,
  userType: decode(localStorage.token).type,
});

const mapDispatchToProps = (dispatch) => ({
  fetchResources: (table, sortObject) =>
    fetchResources(table, sortObject, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableHeader);
