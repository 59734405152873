export const KYC_RECEIPT_JOB_TYPES = ["jt_1", "jt_6", "jt_11"];
export const JobTypes = {
  /** BIOMETRIC_KYC Verify the ID information of your users using facial biometrics */
  BIOMETRIC_KYC: 1,
  /** SMART_SELFIE_AUTHENTICATION Used to identify your existing users. */
  SMART_SELFIE_AUTHENTICATION: 2,
  /** SMART_SELFIE_REGISTRATION Used to verify and register a user for future authentication. */
  SMART_SELFIE_REGISTRATION: 4,
  /**
   * Verifies identity information of a person with their personal
   * information and ID number from one of our supported ID Types.
   */
  BASIC_KYC: 5,
  /**
   * ENHANCED_KYC query the Identity Information for an individual using their
   * ID number from one of our supported.
   */
  ENHANCED_KYC: 5,
  /** DOCUMENT_VERIFICATION Detailed user information retrieved from the ID issuing authority. */
  DOCUMENT_VERIFICATION: 6,
  /**
   * BUSINESS_VERIFICATION Verify the authenticity of Document IDs of your users
   * and confirm it belongs to the user using facial biometrics.
   */
  BUSINESS_VERIFICATION: 7,
  /** Updates the photo on file for an enrolled user */
  UPDATE_PHOTO: 8,

  /** Compares document verification to an id check */
  COMPARE_USER_INFO: 9,

  /** Anti-money laundering */
  AML: 10,

  /** Document Verification x KYC */
  ENHANCED_DOCUMENT_VERIFICATION: 11,

  /** E-Signature */
  E_SIGNATURE: 12,

  /** Phone-Number-Verification */
  PHONE_NUMBER_VERIFICATION: 13,
};

export const SMILE_PRODUCTS_MAP = {
  biometric_kyc: {
    id: "biometric_kyc",
    name: "Biometric KYC",
    short_description: "Retrieve Identity Information Using Biometrics",
    long_description:
      "Retrieve personal information from government records and match a selfie to official ID photo.",
    link: "https://docs.usesmileid.com/products/for-individuals-kyc/biometric-kyc",
  },
  enhanced_document_verification: {
    id: "enhanced_document_verification",
    name: "Enhanced Document Verification",
    short_description: "Verify Identity Documents",
    long_description:
      "Verify the authenticity of an ID document and match a selfie to the ID card photo.",
    link: "https://docs.usesmileid.com/products/for-individuals-kyc/document-verification",
  },
  enhanced_kyc: {
    id: "enhanced_kyc",
    name: "Enhanced KYC",
    short_description: "Retrieve Identity Information",
    long_description:
      "Retrieve personal information from official government records.",
    link: "https://docs.usesmileid.com/products/for-individuals-kyc/identity-lookup",
  },
  doc_verification: {
    id: "doc_verification",
    name: "Document Verification",
    short_description: "Verify Identity Documents",
    long_description:
      "Verify the authenticity of an ID document and match a selfie to the ID card photo.",
    link: "https://docs.usesmileid.com/products/for-individuals-kyc/document-verification",
  },
};

export const COUNTRY_OPTIONS = {
  NG: {
    name: "Nigeria",
    flag: String.fromCodePoint(0x1f1f3) + String.fromCodePoint(0x1f1ec),
  },
  KE: {
    name: "Kenya",
    flag: String.fromCodePoint(0x1f1f0) + String.fromCodePoint(0x1f1ea),
  },
  GH: {
    name: "Ghana",
    flag: String.fromCodePoint(0x1f1ec) + String.fromCodePoint(0x1f1ed),
  },
  ZA: {
    name: "South Africa",
    flag: String.fromCodePoint(0x1f1ff) + String.fromCodePoint(0x1f1e6),
  },
  UG: {
    name: "Uganda",
    flag: String.fromCodePoint(0x1f1fa) + String.fromCodePoint(0x1f1ec),
  },
  US: {
    name: "USA",
    flag: String.fromCodePoint(0x1f1fa) + String.fromCodePoint(0x1f1f8),
  },
};
