import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";

import IconInfo from "resources/img/icons/info-blue.svg";
import { fetchCallbackURL, setCallbackURL } from "util/api_util";
import { getAdminPartnerId } from "util/selectors";
import { Toast } from "util/Toast";
import ErrorAndMessageBar from "../error_and_message_bar";

function Callback(props) {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [validationError, setValidationError] = useState("");

  const user = jwtDecode(localStorage.token);
  const partnerId = getAdminPartnerId();
  const env = props.displayEnvironment ? "Production" : "Sandbox";
  const inverseEnv = env === "Production" ? "Sandbox" : "Production";

  useEffect(() => {
    const fetchData = async () => {
      let result;

      try {
        if (user && user.type === "admin" && partnerId) {
          result = await fetchCallbackURL({ partnerId });
        } else {
          result = await fetchCallbackURL();
        }

        if (result.error) {
          throw new Error(result.error);
        } else {
          setUrl(result.callback || "");
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        Toast.fire({ title: e.message });
      }
    };

    fetchData();
  }, [props.displayEnvironment]);

  const linkValidator = (value) => {
    if (!value) {
      setValidationError("");
      return;
    }
    try {
      const url = new URL(value);
      if (url) {
        setValidationError("");
      }
    } catch (error) {
      setValidationError("Callback url is not valid. Please enter a valid url");
    }
  };

  const handleChange = (event) => {
    setUrl(event.target.value);
    linkValidator(event.target.value);
  };

  const submitCallback = () => {
    if (validationError) {
      return;
    }
    setModalOpen(true);
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const saveData = async (event) => {
    event.preventDefault();

    const payload = {
      callback: url,
    };

    setIsLoading(true);

    try {
      const result = await setCallbackURL(payload);

      if (result.error) {
        throw new Error(result.error);
      } else if (result.callback !== null) {
        setUrl(result.callback);
        Toast.fire({ title: "Callback URL updated" });
      }
    } catch (e) {
      Toast.fire({ title: e.message });
    } finally {
      setIsLoading(false);
      setModalOpen(false);
    }
  };

  return (
    <div className="callback flow">
      <div className="breadcrumbs">
        <Link
          to="/partner/developer_tools"
          className="breadcrumb-link breadcrumb-link--inactive"
        >
          Developer Tools
        </Link>
        <span> &gt; </span>
        <span>Default Callback URL</span>
      </div>

      <div className="callout callout--card" style={{ maxWidth: "none" }}>
        <img src={IconInfo} alt="info" />
        <div className="flow" style={{ "--flow-space": ".8rem" }}>
          <h2>Default Callback URL</h2>

          <p>
            Most of the requests you submit are processed asynchronously, which
            means that the results are not immediately available. We require
            callbacks so we can POST JSON responses to you as we process the
            request. We will send responses to the default callback URL you set
            on this page unless you specify a callback URL while making a
            request.
          </p>

          <a
            href="https://docs.usesmileid.com/further-reading/faqs/how-do-i-setup-a-callback"
            className="btn btn--link"
            target="_blank"
            rel="noreferrer noopener"
          >
            View Docs
          </a>
        </div>
      </div>

      <div className="callout callout--small">
        <p>
          You will only get responses to your
          <span
            className={env === "Production" ? "color-approved" : "color-warn"}
          >
            &nbsp;{env} jobs&nbsp;
          </span>
          on this callback URL. To set your {inverseEnv} callback URL, toggle to{" "}
          {inverseEnv}
        </p>
      </div>

      <form className="flow form" noValidate>
        <div className="input-group">
          {/* <p>https://</p> */}
          <input
            type="url"
            placeholder="https://api.smileidentity.com/callback"
            disabled={isLoading || user.type === "admin"}
            name="callback"
            onChange={handleChange}
            value={url}
          />
        </div>

        {user.type !== "admin" && (
          <button
            className="btn btn-primary"
            type="button"
            onClick={submitCallback}
            disabled={isLoading}
          >
            Update URL
          </button>
        )}
      </form>

      {validationError && <ErrorAndMessageBar errors={validationError} />}

      {modalOpen && (
        <div className="modal">
          <div
            className="flow modal__body"
            style={{ "--flow-space": "1.5rem" }}
          >
            <h2>Are you sure?</h2>

            <p>
              By setting this, all {env} jobs that don’t have a callback set in
              the request will have their response sent to this URL
            </p>

            <div>
              <button className="btn btn-primary" onClick={saveData}>
                Confirm
              </button>
              <button className="btn btn-red--outline" onClick={toggleModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Callback;
