import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import Header from "components/reusable/header";
import { Loader } from "components/reusable/Loader";
import SmileLock from "resources/img/brand/smile-id__symbol--96x96.svg";
import { resetPassword } from "util/api_util";

function ResetPassword(props) {
  const history = useHistory();
  const resetPasswordToken = props.match.params.token;

  React.useEffect(() => {
    if (!resetPasswordToken) {
      history.push("/login");
    }
  }, [resetPasswordToken, history]);

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageType, setMessageType] = React.useState(undefined);

  const [formErrors, setFormErrors] = React.useState({
    password: {
      lowercase: false,
      uppercase: false,
      special: false,
      number: false,
      minimum: false,
    },
    confirmPassword: false,
  });

  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    validatePassword();
    setDisabled(!isFormValid());
  }, [password, confirmPassword]);

  const isFormValid = () =>
    password === confirmPassword &&
    Object.values(formErrors.password).every((value) => value === true);

  const validatePassword = () => {
    const passwordErrors = {};
    const specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    const newErrors = { ...formErrors };

    passwordErrors.lowercase = password.search(/[a-z]/) >= 0;
    passwordErrors.uppercase = password.search(/[A-Z]/) >= 0;
    passwordErrors.special = specialChars.test(password);
    passwordErrors.number = password.search(/[0-9]/) >= 0;
    passwordErrors.minimum = password.length >= 8;
    newErrors.password = passwordErrors;

    setFormErrors(newErrors);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    setLoading(true);

    const data = {
      password: confirmPassword,
      token: resetPasswordToken,
    };

    // TODO: use the errors from the servers
    resetPassword(data).then((resp) => {
      if (resp.errors) {
        setMessage(resp.errors[0]);
        setMessageType("error");
        setLoading(false);
        setDisabled(false);
      } else {
        history.push("/");
      }
    });
  };

  const msgClass = classNames("message message--create-password", {
    "message--success": messageType === "success",
    "message--error": messageType === "error",
  });

  return (
    <div>
      <Header envSlider={false} />
      <div className="newsmile-container__create-password">
        <div className="smile-create-password__container">
          <div className="smile-create-password__icon-container">
            <img
              className="smile-create-password__icon"
              src={SmileLock}
              alt="logo"
            />
          </div>
          <div className="smile-create-password__heading">
            {" "}
            Reset your password{" "}
          </div>
          <div
            className={msgClass}
            style={{
              display: message ? "block" : "none",
            }}
          >
            {message}
          </div>
          <form
            className="smile-form smile-form--create-password"
            id="new_account"
            onSubmit={onFormSubmit}
          >
            <div className="field">
              <br />
              <label> Password </label>
              <input
                autoComplete="off"
                type="password"
                id="password"
                name="password"
                className="input--line"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />

              <br />
              <br />

              <div
                className={
                  formErrors.password.lowercase ? "success" : "failure"
                }
              >
                {" "}
                - One lowercase letter{" "}
              </div>
              <div
                className={
                  formErrors.password.uppercase ? "success" : "failure"
                }
              >
                {" "}
                - One uppercase letter{" "}
              </div>
              <div
                className={formErrors.password.special ? "success" : "failure"}
              >
                {" "}
                - One special character{" "}
              </div>
              <div
                className={formErrors.password.number ? "success" : "failure"}
              >
                {" "}
                - One number{" "}
              </div>
              <div
                className={formErrors.password.minimum ? "success" : "failure"}
              >
                {" "}
                - 8 characters minimum{" "}
              </div>
            </div>

            <div className="field">
              <br />
              <label> Confirm Password </label>
              <input
                autoComplete="off"
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                className="input--line"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
              <div
                className="invalid-feedback"
                style={{
                  display:
                    confirmPassword && password !== confirmPassword
                      ? "block"
                      : "none",
                }}
              >
                {password !== confirmPassword && "Passwords do not match"}
              </div>
            </div>

            <br />
            <div className="button-container--center">
              <button
                disabled={disabled}
                className="btn btn-primary smile-login__button"
                type="submit"
              >
                {loading && <Loader />}
                &nbsp;&nbsp;Reset password
              </button>
            </div>
          </form>
          <div className="fixed-dots--bottom-across" />
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
