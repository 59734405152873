import React from "react";
import { parseISO } from "date-fns";
import { Link, useHistory, useParams } from "react-router-dom";
import { getProviderImage } from "util/actions_helpers";
import { fetchIDStatusMetrics, fetchIDTypeMetrics } from "util/api_util";
import { formatLocalisedDate, getCountry } from "util/format_helpers";
import { IdHistoryChart } from "./id_history_chart";
import { Loader } from "./Loader";

interface RouteParams {
  country: string;
  id_type: string;
  provider: string;
}

interface MetricPoint {
  country: string;
  date: string | Date;
  id_type: string;
  provider?: string;
  status?: string;
  total_jobs: number;
  unavailable: number;
}

const IDHistory: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [idStatus, setIdStatus] = React.useState<MetricPoint[]>([]);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [lastCheck, setLastCheck] = React.useState<string | undefined>(
    undefined,
  );
  const [uptime, setUptime] = React.useState<MetricPoint | undefined>(
    undefined,
  );
  const history = useHistory();

  const { country, id_type: idType, provider } = useParams<RouteParams>();

  React.useEffect(() => {
    setLoading(true);
    const fetchMetrics = async () => {
      try {
        const idMetrics = (await fetchIDTypeMetrics({
          country,
          id_type: idType,
        })) as { points: MetricPoint[]; last_check?: string };
        setLoading(false);
        setIdStatus(
          idMetrics.points
            .map((point) => ({
              ...point,
              date: parseISO(point.date as string),
            }))
            .sort((a, b) => a.date.getTime() - b.date.getTime()),
        );
        setLastCheck(idMetrics.last_check);

        const upTimeMetrics = (await fetchIDStatusMetrics({
          country,
          id_type: idType,
        })) as { points: MetricPoint[] };
        setUptime(upTimeMetrics.points[0] || undefined);
      } catch (error) {
        if (error instanceof Error) setError(error.message);
      }
    };

    fetchMetrics();
  }, []);

  const redirectBack = (event: React.MouseEvent<HTMLAnchorElement>) => {
    sessionStorage.setItem("url", event.currentTarget.dataset.value as string);
    history.goBack();
  };

  function serviceMap(data: MetricPoint | undefined) {
    if (!data || !data.status) {
      return {
        color: "#aaa",
        status: "No Data",
        lightenedColor: "#f2f3f7",
      };
    }
    if (data.status === "online") {
      return {
        color: "#17dc89",
        status: "Available",
        lightenedColor: "#D3F8E8",
      };
    }
    if (data.status === "offline") {
      return {
        color: "#F15A5A",
        status: "Service Outage",
        lightenedColor: "#FDE0E0",
      };
    }
    return {
      color: "#d9984f",
      status: "Service Interruption",
      lightenedColor: "#FDF0DD",
    };
  }

  const imgSrc = getProviderImage(country, idType, provider);

  const uptimeStatus = serviceMap(uptime);

  return (
    <>
      <div className="breadcrumbs">
        <a
          data-value="id_status"
          className="breadcrumb-link breadcrumb-link--inactive"
          onClick={redirectBack}
        >
          ID API Status
        </a>
        <span> &gt; </span>
        <span>
          {getCountry(country) || country} {idType.replace("_", " ")}
        </span>
      </div>

      {loading && (
        <div style={{ textAlign: "center" }} className="loader__container">
          <div className="loader loader--centered" />
        </div>
      )}

      <div className="id-history__container">
        <div className="id-history__card-container">
          <div className="id-history__card-item">
            <div className="id-status__report-downtime">
              <div className="result-element-dot result-element-dot--offline" />
              <Link to="/partner/support/tickets">Report Downtime</Link>
            </div>
            <div className="id-history__card-info">
              <div className="id-history__card-image">
                <img src={imgSrc} alt={imgSrc} />
              </div>

              <div className="id-history__card-details">
                <div className="id-history__card-detail">
                  <span className="id-history__card-detail-name  id-provider-name">
                    {getCountry(country) || country} {idType.replace("_", " ")}
                  </span>
                  {loading ? (
                    <Loader />
                  ) : (
                    <div
                      className="id-provider__status-container result-element"
                      style={{
                        background: uptimeStatus.lightenedColor,
                      }}
                    >
                      <span
                        className="result-element-dot"
                        style={{
                          background: uptimeStatus.color,
                        }}
                      />
                      <span className="id-provider__status">
                        {uptimeStatus.status}
                      </span>
                    </div>
                  )}
                </div>
                <div className="id-provider__timestamp-container id-history__card-detail-time">
                  Latest:{" "}
                  <span className="id-provider__timestamp">
                    {lastCheck &&
                      formatLocalisedDate(lastCheck, "ddd MMM DD, YYYY HH:mm")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="id-history__card-item id-history__card-chart">
            <div className="status-chart__legend" />

            <IdHistoryChart data={idStatus} />
          </div>
        </div>

        <p className="error">{error}</p>
      </div>
    </>
  );
};

export default IDHistory;
