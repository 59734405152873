import React from "react";
import { useSetState } from "ahooks";
import moment from "moment";
import { useHistory } from "react-router-dom";

import ErrorAndMessageBar from "components/reusable/error_and_message_bar";
import Table from "components/reusable/table";
import Paginated from "containers/reusable/paginated";
import SortableHeaderContainer from "containers/reusable/SortableHeaderContainer";
import {
  DisplayEnvironment,
  getEnvironment,
} from "contexts/displayEnvironment";
import { useQueryParams } from "hooks/useQueryParams";
import { amendUrl, buildState, fetchResources } from "util/api_util";
import { userHasPermission } from "util/route_util";
import { ACCESS_LOGS_HEADERS } from "util/table_headers";

function AccessLogs() {
  const history = useHistory();
  const queryParams = useQueryParams();

  const { environment: displayEnvironment } =
    React.useContext(DisplayEnvironment);

  const [state, setState] = useSetState({
    number_of_items: queryParams.number_of_items || 20,
    page: queryParams.page || 1,
    partner: { value: queryParams.partner },
    last_job_key: queryParams.last_job_key,
    first_job_key: queryParams.first_job_key,
    startDate: queryParams.startDate
      ? moment(queryParams.startDate.replace(/(%22)/g, ""))
      : undefined,
    endDate: queryParams.endDate
      ? moment(queryParams.endDate.replace(/(%22)/g, ""))
      : undefined,
    query: queryParams.query || "",
    loading: false,
    errors: undefined,
    job_type: { value: queryParams.job_type },
    total: 0,
    logs: [],
  });

  React.useEffect(() => {
    const hasPermission = userHasPermission(["partner full_read"]);
    if (!hasPermission) {
      history.push("/404");
      return;
    }
    getData();
  }, [
    displayEnvironment,
    state.page,
    state.last_job_key,
    state.first_job_key,
    state.number_of_items,
    state.startDate,
    state.endDate,
    state.query,
    state.job_type,
    state.partner,
  ]);

  const handlePageChange = (page) => {
    let last_job_key;
    let first_job_key;
    if (page > state.page) {
      last_job_key = state.logs[state.logs.length - 1].id;
    } else if (page < state.page) {
      first_job_key = state.logs[0].id;
    }
    setState({
      page,
      last_job_key,
      first_job_key,
    });
  };

  const redirect = (data) => {
    window.open(
      `/admin/job/${getEnvironment()}/${data.id}`,
      "_blank",
      "noreferrer",
    );
  };

  const getData = () => {
    setState({
      loading: true,
      errors: undefined,
    });
    const keys = [
      "number_of_items",
      "page",
      "partner",
      "query",
      "last_job_key",
      "first_job_key",
      "startDate",
      "endDate",
      "query",
      "partner",
      "job_type",
    ];
    const request = keys.reduce((acc, key) => {
      if (state[key]) {
        acc[key] = state[key];
      }
      return acc;
    }, {});

    const url = amendUrl("admin/access_logs/", request);
    history.push(url);
    const queryState = buildState(history.location.search); // access query params

    fetchResources("access_logs", queryState)
      .then((resp) => {
        const { logs, errors, pagination } = resp;
        setState({
          page: pagination.page,
          total: pagination.nextPage,
          loading: false,
          errors,
          logs,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
          errors: err.message,
        });
      });
  };

  const changeNumberOfItems = (value) => {
    setState({ number_of_items: value });
  };

  const handleChange = (key, value) => {
    setState({
      [key]: value,
    });
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    setState({
      page: 1,
    });
  };

  const clearSearch = () => {
    setState({
      page: 1,
      startDate: undefined,
      endDate: undefined,
      query: "",
      partner: "",
      job_type: "",
    });
  };

  return (
    <>
      <ErrorAndMessageBar errors={state.errors} />
      <SortableHeaderContainer
        startDate={state.startDate}
        endDate={state.endDate}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        query={state.query}
        partner={state.partner}
        clearSearch={clearSearch}
        jobType={state.job_type}
        filterByJobType
      />
      {state.loading ? (
        <div className="loader" />
      ) : (
        <div>
          <Table
            clickableRow
            data={state.logs}
            headers={ACCESS_LOGS_HEADERS}
            tableType="access_logs"
            history={history}
            redirect={redirect}
          />
          <Paginated
            activePage={state.page}
            itemsCountPerPage={state.number_of_items}
            totalItems={state.total}
            handlePageChange={handlePageChange}
            changeNumberOfItems={changeNumberOfItems}
          />
        </div>
      )}
    </>
  );
}

export default AccessLogs;
