import React from "react";
import classNames from "classnames";
import styled from "styled-components";

import logo from "resources/img/brand/logo-white-horizontal.svg";
import copiedIcon from "resources/img/icons/copied.svg";
import copyIcon from "resources/img/icons/copy.svg";
import emptyWarning from "resources/img/icons/empty-warning.svg";

import {
  relevantActionsWithValues,
  getActionLabel,
  getProviderImage,
  getProviderImageName,
  getSourceIcon,
  getPhotoWithLabels,
  machineAnalysisUpdate,
} from "util/actions_helpers";
import { fetchPhoneVerifications } from "util/api_util";
import { KYC_RECEIPT_JOB_TYPES, JobTypes } from "util/constants";
import { formatLocalisedDate, copy } from "util/format_helpers";
import {
  isEnrollWithIDCardImage,
  jobTypeToProductNameMap,
} from "util/selectors";
import ActionsInterface from "../actions_interface";

import BusinessVerificationInfo from "../business_verification_info";
import JobResultPhotos from "../job_result_photos";
import PrintPdfButton from "../print_pdf_button";
import Tooltip from "../tooltip";
import IdInfoAccordion from "./IdInfoAccordion";
import MetadataAccordion from "./MetadataAccordion";
import UserProvidedInfoAccordion from "./UserProvidedInfoAccordion";

const shouldDisplayPrintPDF = (resultPayload) => {
  if (resultPayload.ran_as_job_type === JobTypes.BUSINESS_VERIFICATION) {
    return true;
  }

  const productType = resultPayload.product_type;
  const resultText = resultPayload?.result?.ResultText;
  const isProvisionalResult = /provisional/i.test(resultText);

  return KYC_RECEIPT_JOB_TYPES.includes(productType) && !isProvisionalResult;
};

const toHRF = (string) => string.replace(/\_|-/, " ").trim();
const normalizeString = (s) =>
  typeof s === "string" && s !== "null" ? s.trim() : undefined;

const getSourceLabel = (resultPayload) => {
  const sdk = normalizeString(resultPayload.sdk);
  const sdkVersion = normalizeString(resultPayload.sdk_version);
  if (sdk) {
    return `${toHRF(sdk)}${sdkVersion ? ` - ${sdkVersion}` : ""}`;
  }

  if (
    resultPayload?.job_source?.includes("async") &&
    !resultPayload.result?.Source?.includes("Async")
  ) {
    return "Async ";
  }

  return resultPayload.result.Source || "";
};

const DetailFeild = ({ label, value, children }) => (
  <div className="job-field__container">
    <div className="job-field__label">{label}:</div>
    {value && <div className="job-field__value">{value}</div>}
    {children}
  </div>
);

function JobResultCard(props) {
  const currentJobType = parseInt(props.resultPayload.ran_as_job_type, 10);
  const isPhoneVerificationJob =
    currentJobType === JobTypes.PHONE_NUMBER_VERIFICATION;

  const [copied, setCopied] = React.useState(false);
  const [phoneVerification, setPhoneVerification] = React.useState();

  React.useEffect(() => {
    if (currentJobType === JobTypes.PHONE_NUMBER_VERIFICATION) {
      fetchPhoneVerifications(props.resultPayload.smile_job_id).then((data) => {
        if (data?.error) {
          console.error(data.error);
          return;
        }

        setPhoneVerification(data);
      });
    }
  }, [props.resultPayload.ran_as_job_type]);

  const isJobType7 = currentJobType === 7;

  const JobIDcopy = () => {
    copy("job-field__job-id", "Job Id copied to clipboard!");
    setCopied(!copied);
  };

  const replaceIfPresent = (value, originalValue, replacement) => {
    if (value) {
      return value.replace(originalValue, replacement);
    }
    return value;
  };

  const renderActions = (sections, headers) => {
    const sectionHeaders = Object.keys(sections);
    // Get only actions with headers in dynamo
    const actionHeaders = headers.filter((actionHeader) =>
      sectionHeaders.includes(actionHeader),
    );

    return actionHeaders.map((key) => (
      <div key={key} className="job-result__section-result-group">
        <div className="job-result__section--section_header">{key}</div>
        {sections[key]}
      </div>
    ));
  };

  const {
    actionsMap,
    idInfo,
    images,
    resultPayload,
    resultCodes,
    metadata,
    resultTitle,
  } = props;

  if (Object.keys(resultPayload).length === 0) {
    return (
      <div className="job-result__section">
        <div className="no-info__container">
          <img
            className="icon--large"
            src={emptyWarning}
            alt="No information to show"
          />
          <div className="no-info">No information to show</div>
        </div>
      </div>
    );
  }
  const actions =
    currentJobType === 13 && phoneVerification?.actions
      ? phoneVerification?.actions
      : machineAnalysisUpdate(resultPayload.result.Actions);
  const idCardImageAction = actions ? actions.Selfie_To_ID_Card_Compare : "";
  const resultCode =
    resultPayload.result_code || resultPayload.result.ResultCode;

  const isJobType1IdCardImage = isEnrollWithIDCardImage(
    resultPayload.ran_as_job_type,
    {
      entered: resultPayload.entered,
      url: resultPayload.target,
      action: idCardImageAction,
    },
  );

  const photos = getPhotoWithLabels(images, resultPayload.smile_job_id);

  const resultText = resultPayload?.result?.ResultText;
  // group the items by headers
  const sections = Object.keys(
    relevantActionsWithValues(
      actionsMap.action_jobs,
      actions,
      resultPayload.ran_as_job_type,
    ),
  ).reduce((r, item) => {
    const label = getActionLabel(
      actionsMap.action_labels,
      item,
      resultPayload.ran_as_job_type,
      isJobType1IdCardImage,
    );
    const title = actionsMap.action_groups
      ? actionsMap.action_groups[item]
      : "";
    const value = actions[item];

    r[title] ||= [];
    r[title].push(
      <ActionsInterface
        title={title}
        keyLabel={label}
        keyName={item}
        value={value}
        confidenceValue={resultPayload.result.ConfidenceValue}
        actionIcons={actionsMap.action_icons}
        heatmapActions={actionsMap.heatmap_actions}
        key={label}
      />,
    );

    return r;
  }, Object.create(null));

  const className = classNames("job-result__container", {
    "job-result__container--centered": isPhoneVerificationJob,
  });

  return (
    <Container isPhoneVerificationJob={isPhoneVerificationJob}>
      <div className={className}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="job-result__container-header newsmile-heading-h1">
            Result Overview{" "}
            <span className="newsmile-heading-h1--greyed">
              - {jobTypeToProductNameMap[resultPayload.product_type]}
            </span>
          </div>
          {shouldDisplayPrintPDF(resultPayload) && (
            <PrintPdfButton resultPayload={resultPayload} />
          )}
        </div>
        {!isJobType7 && (
          <div className="job-result__card">
            <div className="job-result__row">
              {!isPhoneVerificationJob && (
                <JobResultPhotos
                  resultPayload={resultPayload}
                  photos={photos}
                />
              )}
              <div className="job-result__section job-result__section--job-overview">
                <DetailFeild
                  label="Job Type"
                  value={resultPayload.ran_as_job_type}
                />
                <DetailFeild label="Job ID">
                  <div className="job-field__value job-field__value-split">
                    <div id="job-field__job-id">
                      {resultPayload.smile_job_id}
                    </div>
                    <div
                      onClick={JobIDcopy}
                      className="job-result__copy-button"
                    >
                      <img src={copied ? copiedIcon : copyIcon} alt="copy" />
                      &nbsp;
                      <span className="copy">{copied ? "Copied" : "Copy"}</span>
                    </div>
                  </div>
                </DetailFeild>

                <DetailFeild
                  label="User ID"
                  value={resultPayload.result.PartnerParams.user_id}
                />
                <DetailFeild
                  label="Date"
                  value={formatLocalisedDate(
                    resultPayload.smile_reference_created_at,
                    "dddd, DD MMM YYYY",
                  )}
                />
                <DetailFeild
                  label="Time"
                  value={formatLocalisedDate(
                    resultPayload.smile_reference_created_at,
                    "h:mm:ss A",
                  )}
                />
                <DetailFeild label="Source">
                  <div className="job-field__value job-field__value-split">
                    <div style={{ textTransform: "capitalize" }}>
                      {getSourceLabel(resultPayload)}
                    </div>
                    {getSourceIcon(resultPayload.sdk) && (
                      <div>
                        <img
                          className="icon--x-small"
                          src={getSourceIcon(resultPayload.sdk)}
                          alt={resultPayload.sdk}
                        />
                      </div>
                    )}
                  </div>
                </DetailFeild>
                <DetailFeild label="Result">
                  <div className="job-field__value job-field__value-split job-field--job-overview__result">
                    <div
                      className={`result-element result-element--${replaceIfPresent(
                        resultTitle,
                        / /g,
                        "-",
                      )}`}
                    >
                      <div
                        className={`result-element-dot result-element-dot--${replaceIfPresent(
                          resultTitle,
                          / /g,
                          "-",
                        )}`}
                      />
                      <div>{resultTitle}</div>
                    </div>
                    <div className="job-field--job-overview__code">
                      <div className="key">Code:&nbsp;</div>
                      <div
                        className={`text--${replaceIfPresent(
                          resultTitle,
                          / /g,
                          "-",
                        )}`}
                      >
                        {" "}
                        <a
                          href="https://docs.usesmileid.com/further-reading/result-codes"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {resultCode} {resultText}
                        </a>
                      </div>
                    </div>
                  </div>
                </DetailFeild>
              </div>
            </div>

            <div className="job-result__row job-result__row--2">
              {!isPhoneVerificationJob && (
                <div className="job-result__section job-result__section--ai">
                  <div className="job-result__section__header  job-result__section--ai__header job-result_section__header-image-align">
                    <div className="job-result__section--ai__header-image">
                      <img src={logo} alt="logo" />
                    </div>
                    <div>Job Summary</div>
                  </div>

                  {actions ? (
                    renderActions(sections, actionsMap.action_headers)
                  ) : (
                    <div className="job-result__section">
                      <div className="no-info__container">
                        <img
                          className="icon--large"
                          src={emptyWarning}
                          alt="No information to show "
                        />
                        <div className="no-info">No information to show</div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="job-result__section job-result__section--details">
                <div className="job-result__section__header  job-result__section--ai__header job-result_section__header-image-align">
                  <div>Details</div>
                </div>

                <InfoDiv>
                  <IdInfoAccordion
                    idInfo={phoneVerification?.actions || idInfo}
                    jobType={currentJobType}
                    originalIdphoto={resultPayload.original_id_photo}
                    piiDataExpired={resultPayload.pii_data_expired}
                    strippedPii={resultPayload.stripped_pii}
                  />

                  <UserProvidedInfoAccordion
                    jobType={currentJobType}
                    userInformation={
                      phoneVerification?.user_provided_details || resultPayload
                    }
                  />

                  <MetadataAccordion metadata={metadata} />
                </InfoDiv>
              </div>
            </div>
          </div>
        )}

        {isJobType7 && (
          <>
            <div className="job-result__section__header  job-result__section--ai__header">
              <div className="job-result__section--ai__header-image">
                <img src={logo} alt="logo" />
              </div>
            </div>
            <div className="job-result__card">
              <div className="job-result__row">
                <div className="box job-card">
                  <div>
                    <DetailFeild label="ID Authority Queried">
                      <div className="job-field__value job-field__provider-img">
                        <img
                          src={getProviderImage(
                            idInfo.business_verification_country,
                            idInfo.business_verification_id_type,
                          )}
                          alt={getProviderImage(
                            idInfo.business_verification_country,
                            idInfo.business_verification_id_type,
                          )}
                        />
                        <p>
                          {getProviderImageName(
                            idInfo.business_verification_country,
                            idInfo.business_verification_id_type,
                          )}
                        </p>
                      </div>
                    </DetailFeild>
                    <DetailFeild
                      value={idInfo.business_verification_country}
                      label="Country"
                    />

                    <DetailFeild
                      value={idInfo.business_verification_id_type}
                      label="ID Type"
                    />
                  </div>

                  <div>
                    {!!resultPayload?.result?.company_information
                      ?.legal_name && (
                      <DetailFeild
                        value={
                          resultPayload?.result?.company_information?.legal_name
                        }
                        label="Business Name"
                      />
                    )}
                    <DetailFeild
                      label="Registration Number"
                      value={
                        idInfo.user_detail_id_number ||
                        resultPayload?.result?.company_information
                          ?.registration_number
                      }
                    />

                    {!!resultPayload?.result?.company_information
                      ?.company_type && (
                      <DetailFeild
                        label="Business Type"
                        value={
                          resultPayload?.result?.company_information
                            ?.company_type
                        }
                      />
                    )}
                  </div>
                </div>

                <div className="box job-card">
                  <DetailFeild
                    value={resultPayload.ran_as_job_type}
                    label="Job Type"
                  />

                  <DetailFeild label="Job ID">
                    <div className="job-field__value job-field__value-split">
                      <div id="job-field__job-id">
                        {resultPayload.smile_job_id}
                      </div>
                      <div
                        onClick={JobIDcopy}
                        className="job-result__copy-button"
                      >
                        <img src={copied ? copiedIcon : copyIcon} alt="copy" />
                        &nbsp;
                        <span className="copy">
                          {copied ? "Copied" : "Copy"}
                        </span>
                      </div>
                    </div>
                  </DetailFeild>
                  <DetailFeild
                    value={resultPayload.result.PartnerParams.user_id}
                    label="User ID"
                  />

                  <DetailFeild
                    value={formatLocalisedDate(
                      resultPayload.smile_reference_created_at,
                      "dddd, DD MMM YYYY",
                    )}
                    label="Date"
                  />
                  <DetailFeild
                    value={formatLocalisedDate(
                      resultPayload.smile_reference_created_at,
                      "h:mm:ss A",
                    )}
                    label="Time"
                  />

                  <DetailFeild label="Source">
                    <div className="job-field__value job-field__value-split">
                      <div style={{ textTransform: "capitalize" }}>
                        {getSourceLabel(resultPayload)}
                      </div>
                      {getSourceIcon(resultPayload.sdk) && (
                        <div>
                          <img
                            className="icon--x-small"
                            src={getSourceIcon(resultPayload.sdk)}
                            alt={resultPayload.sdk}
                          />
                        </div>
                      )}
                    </div>
                  </DetailFeild>
                  <DetailFeild label="Result">
                    <div className="job-field__value job-field__value-split job-field--job-overview__result">
                      <div
                        className={`result-element result-element--${resultCodes[
                          resultCode
                        ].result.replace(/ /g, "-")}`}
                      >
                        <div
                          className={`result-element-dot result-element-dot--${resultCodes[
                            resultCode
                          ].result.replace(/ /g, "-")}`}
                        />
                        <div>{resultCodes[resultCode].result}</div>
                      </div>
                      <div className="job-field--job-overview__code">
                        <div className="key">Code:&nbsp;</div>
                        <div
                          className={`text--${resultCodes[
                            resultCode
                          ].result.replace(/ /g, "-")}`}
                        >
                          {" "}
                          <a
                            href="https://docs.usesmileid.com/further-reading/result-codes"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {resultCode} {resultText}
                          </a>
                        </div>
                      </div>
                    </div>
                  </DetailFeild>
                </div>
              </div>

              {!!resultPayload.result.company_information && (
                <div className="stack">
                  <h2 className="cluster">
                    ID Authority Response
                    <Tooltip>
                      We can only display information during the data retention
                      period
                    </Tooltip>
                  </h2>

                  <hr style={{ borderColor: "aliceblue" }} />

                  <BusinessVerificationInfo payload={resultPayload.result} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

function Container({ children, isPhoneVerificationJob }) {
  if (isPhoneVerificationJob) {
    return <ContainerDiv>{children}</ContainerDiv>;
  }
  return children;
}

const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export default JobResultCard;
