import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { StyledButton } from "components/reusable/web_links/StyledButton";
import { putRerunJob } from "util/api_util";
import styles from "./re_run.style.module.css";

function ReRun({ job, toggleModal, amountCharged }) {
  const [messages, setMessages] = useState(undefined);
  const [disabled, setDisabled] = useState(false);
  const [needConfirmation, setNeedConfirmation] = useState(false);
  const [runWithCharges, setRunWithCharges] = useState(false);
  const [complete, setComplete] = useState(false);
  const [newJobId, setNewJobId] = useState(null);
  const { id, environment } = useParams();

  const rerunJob = (e, chargeForJob) => {
    e.preventDefault();
    setDisabled(!disabled);
    setNeedConfirmation(!needConfirmation);
    setRunWithCharges(chargeForJob);
    setTimeout(() => {
      setDisabled(false);
    }, 100);
  };

  const confirm = (e) => {
    e.preventDefault();
    setDisabled(!disabled);
    putRerunJob(id, runWithCharges)
      .then((resp) => {
        if (resp.success) {
          setMessages(
            `Job successfully re-run. New smile_job_id: ${resp.job_id}`,
          );
          setNewJobId(resp.id);
          setComplete(true);
        } else {
          setMessages(resp.error);
          setDisabled(false);
        }
      })
      .catch((err) => {
        setMessages(err.message);
        setDisabled(false);
      });
  };

  const cancel = (e) => {
    e.preventDefault();
    setDisabled(!disabled);
    setNeedConfirmation(!needConfirmation);
    setRunWithCharges(false);
    setTimeout(() => {
      setDisabled(false);
    }, 100);
  };

  return (
    <div className="new-typography new-styles center">
      <div className="messages">
        {complete ? (
          <a href={`/admin/job/${environment}/${newJobId}`}>
            <p id="messages">{messages}</p>
          </a>
        ) : (
          <p id="messages">{messages}</p>
        )}
      </div>
      <h3>
        Re-run partner ID: {job.partner_id} job ID: {job.job_id}
      </h3>
      {complete ? (
        <div className={styles.buttonDiv}>
          <StyledButton
            className={styles.buttonDivButton}
            onClick={toggleModal}
            variant="outline"
          >
            Close
          </StyledButton>
        </div>
      ) : (
        ""
      )}
      {needConfirmation && !complete ? (
        <div className={styles.buttonDiv}>
          <StyledButton
            className={styles.buttonDivButton}
            onClick={(e) => confirm(e)}
            disabled={disabled}
            variant="outline"
          >
            {`Confirm ${runWithCharges ? "with charges" : ""}`}
          </StyledButton>
          <StyledButton
            className={styles.buttonDivButton}
            onClick={(e) => cancel(e)}
            disabled={disabled}
            variant="outline"
          >
            Cancel
          </StyledButton>
        </div>
      ) : (
        ""
      )}
      {!needConfirmation && !complete ? (
        <div className={styles.buttonDiv}>
          <StyledButton
            className={styles.buttonDivButton}
            onClick={(e) => rerunJob(e)}
            disabled={disabled || amountCharged === "0.0"}
            variant="outline"
          >
            Run without charges
          </StyledButton>
          <StyledButton
            className={styles.buttonDivButton}
            onClick={(e) => rerunJob(e, true)}
            disabled={disabled}
            variant="outline"
          >
            Run with charges
          </StyledButton>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ReRun;
