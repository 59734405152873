import * as APIUtil from "util/api_util";
import {
  fetchPendingKYCReviewCount,
  fetchPendingReviewCount,
} from "util/api_util";
import { formatPartnerInfo } from "util/selectors";

export const RECEIVE_REFERENCES = "RECEIVE_REFERENCES";
export const RECEIVE_ACCESS_LOGS = "RECEIVE_ACCESS_LOGS";
export const RECEIVE_RESULTS = "RECEIVE_RESULTS";
export const RECEIVE_JOB = "RECEIVE_JOB";
export const RECEIVE_REVIEWS = "RECEIVE_REVIEWS";
export const RECEIVE_COMPANIES = "RECEIVE_COMPANIES";
export const RECEIVE_COMPANY = "RECEIVE_COMPANY";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";
export const RECEIVE_DELETEDJOB = "RECEIVE_DELETEDJOB";
export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_ERROR = "RECEIVE_ERROR";
export const RECEIVE_PARTNER_INFO = "RECEIVE_PARTNER_INFO";
export const RECEIVE_PARTNERS = "RECEIVE_PARTNERS";
export const CLEAR_ENTERPRISE_STATE = "CLEAR_ENTERPRISE_STATE";
export const RECEIVE_SORTING_PARAMS = "RECEIVE_SORTING_PARAMS";
export const RECEIVE_ENROLLEE_OBJECT = "RECEIVE_ENROLLEE_OBJECT";
export const CLEAR_REVIEWS_STATE = "CLEAR_REVIEWS_STATE";
export const CLEAR_RESULTS_STATE = "CLEAR_RESULTS_STATE";
export const PENDING_KYC_AND_REVIEW_COUNT = "PENDING_KYC_AND_REVIEW_COUNT";

const receiveReferences = (referencesAndCount) => ({
  type: RECEIVE_REFERENCES,
  referencesAndCount,
});

const pendingKYCAndReviewCount = (count) => ({
  type: PENDING_KYC_AND_REVIEW_COUNT,
  ...count,
});

const receiveResults = (resultsAndCount) => ({
  type: RECEIVE_RESULTS,
  resultsAndCount,
});

const receiveAccessLogs = (logsAndCount) => ({
  type: RECEIVE_ACCESS_LOGS,
  logsAndCount,
});

const receiveReviews = (reviewsAndCount) => ({
  type: RECEIVE_REVIEWS,
  reviewsAndCount,
});

const receiveSelectedJob = (job) => ({
  type: RECEIVE_JOB,
  job,
});

const receiveCompanies = (companies) => ({
  type: RECEIVE_COMPANIES,
  companies,
});

const receiveSelectedCompany = (company) => ({
  type: RECEIVE_COMPANY,
  company,
});

const receiveMessage = (message) => ({
  type: RECEIVE_MESSAGE,
  message,
});

const receiveError = (error) => ({
  type: RECEIVE_ERROR,
  error,
});

const receiveUser = (user) => ({
  type: RECEIVE_USER,
  user,
});

export const fetchJob = (smileId, dispatch) =>
  APIUtil.fetchJob(smileId).then((job) => dispatch(receiveSelectedJob(job)));

const receivePartnerInfo = (partnerInfo) => ({
  type: RECEIVE_PARTNER_INFO,
  partnerInfo,
});

const receivePartners = (partners) => ({
  type: RECEIVE_PARTNERS,
  partners,
});

const clearEnterpriseState = () => ({
  type: CLEAR_ENTERPRISE_STATE,
});

const receiveSortingParams = (sortingParams) => ({
  type: RECEIVE_SORTING_PARAMS,
  sortingParams,
});

export const clearReviewsState = () => ({
  type: CLEAR_REVIEWS_STATE,
});

export const clearResultsState = () => ({
  type: CLEAR_RESULTS_STATE,
});

export const fetchResources = (table, sortObject = {}, dispatch) =>
  APIUtil.fetchResources(table, sortObject).then((sortedState) => {
    if (/results/g.exec(table)) {
      return dispatch(receiveResults(sortedState));
    }
    if (table === "smile_references") {
      return dispatch(receiveReferences(sortedState));
    }
    if (table === "access_logs") {
      return dispatch(receiveAccessLogs(sortedState));
    }
    if (table === "reviews" || table === "reviews/completed") {
      return dispatch(receiveReviews(sortedState));
    }
  });

export const submitReview = (reviewInfo, dispatch) =>
  APIUtil.submitReview(reviewInfo).then((newReviews) =>
    dispatch(receiveReviews(newReviews)),
  );

export const fetchCompanies = (dispatch) =>
  APIUtil.fetchCompanies().then((companies) =>
    dispatch(receiveCompanies(companies)),
  );

export const fetchSelectedCompany = (id, dispatch) =>
  APIUtil.fetchSelectedCompany(id).then((company) =>
    dispatch(receiveSelectedCompany(company)),
  );

export const submitCompany = (companyDetails, dispatch) =>
  APIUtil.createOrUpdateCompany(companyDetails).then((companies) =>
    dispatch(receiveCompanies(companies)),
  );

export const dispatchMessage = (message, dispatch) =>
  dispatch(receiveMessage(message));

export const dispatchError = (error, dispatch) => dispatch(receiveError(error));

export const fetchUser = (dispatch) =>
  APIUtil.fetchUser().then((user) => dispatch(receiveUser(user)));

export const updateUser = (id, data, dispatch) =>
  APIUtil.updateUser(id, data).then((user) => dispatch(receiveUser(user)));

export const fetchPartnerInfo = (sortObject, dispatch) =>
  APIUtil.fetchPartnerInfo(sortObject).then((partnerInfo) =>
    dispatch(receivePartnerInfo(formatPartnerInfo(partnerInfo))),
  );

export const loadPendingReviewAndKYCCount = (dispatch) =>
  Promise.all([fetchPendingReviewCount(), fetchPendingKYCReviewCount()])
    .then(([humanReview, kycReview]) =>
      dispatch(pendingKYCAndReviewCount({ humanReview, kycReview })),
    )
    .catch((e) => console.error(e));

export const fetchPartners = (dispatch) =>
  APIUtil.fetchPartners().then((partners) =>
    dispatch(receivePartners(partners)),
  );

export const removeEnterpriseState = (dispatch) =>
  dispatch(clearEnterpriseState());

export const updateSortingParams = (sortingParams, dispatch) =>
  dispatch(receiveSortingParams(sortingParams));

export const removeReviewsState = (dispatch) => dispatch(clearReviewsState());

export const removeResultsState = (dispatch) => dispatch(clearResultsState());
