import React from "react";
import userIDauthImageIcon from "resources/img/icons/governmentIcon.svg";
import livenessIcon from "resources/img/icons/livenessIcon.svg";
import userSelfieIcon from "resources/img/icons/userSelfieIcon.svg";
import {
  LightIcon,
  InvertIcon,
  ExpandIcon,
  RotateLeftIcon,
  ZoomInIcon,
  RotateRightIcon,
  ZoomOutIcon,
  ResetIcon,
  FlipHorizontalIcon,
  FlipVerticalIcon,
} from "./AnimatableSVGs";

const ImageDescriptionMap = {
  id_authority: {
    icon: userIDauthImageIcon,
    description: "Image from ID authority",
  },
  id_authority_document: {
    icon: userIDauthImageIcon,
    description: "Image from ID authority",
  },
  id_card: {
    icon: userIDauthImageIcon,
    description: "Front of ID document",
  },
  id_card_back: {
    icon: userIDauthImageIcon,
    description: "Back of ID document",
  },
  liveness: {
    icon: livenessIcon,
    description: "Liveness image",
  },
  selfie: {
    icon: userSelfieIcon,
    description: "User submitted selfie",
  },
  unknown: {
    icon: userSelfieIcon,
    description: "Known document type",
  },
};

const ImageDescription = ({ descriptionType }) => {
  const { icon, description } =
    ImageDescriptionMap[descriptionType] || ImageDescriptionMap.unknown;

  return (
    <div className="image_description">
      <div className="image_description_icon">
        <img src={icon} alt={description} width="16" height="16" />
      </div>
      <p className="image_description_text p2">{description}</p>
    </div>
  );
};

const ImageTranslationTools = ({
  onZoomIn,
  onZoomOut,
  onReset,
  onRotateLeft,
  onRotateRight,
  onFlipX: onFlipHorizontal,
  onFlipY: onFlipVertical,
}) => (
  <>
    <button className="image_tool_button" onClick={() => onZoomIn()}>
      <div className="image_tool_icon_container">
        <ZoomInIcon cssClass="image_tool_icon" />
        <span className="visually-hidden">Zoom In</span>
      </div>
    </button>
    <button className="image_tool_button" onClick={() => onZoomOut()}>
      <div className="image_tool_icon_container">
        <ZoomOutIcon cssClass="image_tool_icon" />
        <span className="visually-hidden">Zoom Out</span>
      </div>
    </button>
    <button className="image_tool_button" onClick={() => onReset()}>
      <div className="image_tool_icon_container">
        <ResetIcon cssClass="image_tool_icon" />
        <span className="visually-hidden">Reset</span>
      </div>
    </button>
    <button className="image_tool_button" onClick={() => onRotateLeft()}>
      <div className="image_tool_icon_container">
        <RotateLeftIcon cssClass="image_tool_icon" />
        <span className="visually-hidden">Rotate Left</span>
      </div>
    </button>
    <button className="image_tool_button" onClick={() => onRotateRight()}>
      <div className="image_tool_icon_container">
        <RotateRightIcon cssClass="image_tool_icon" />
        <span className="visually-hidden">Rotate Right</span>
      </div>
    </button>
    <button className="image_tool_button" onClick={() => onFlipHorizontal()}>
      <div className="image_tool_icon_container">
        <FlipHorizontalIcon cssClass="image_tool_icon" />
        <span className="visually-hidden">Flip Horizontal</span>
      </div>
    </button>
    <button className="image_tool_button" onClick={() => onFlipVertical()}>
      <div className="image_tool_icon_container">
        <FlipVerticalIcon cssClass="image_tool_icon" />
        <span className="visually-hidden">Flip Vertical</span>
      </div>
    </button>
  </>
);
const ImageFilterTools = ({ handleFilterChange, showFullScreen = false }) => (
  <>
    <button
      className="image_tool_button"
      onClick={() => handleFilterChange("invert")}
    >
      <div className="image_tool_icon_container">
        <InvertIcon cssClass="image_tool_icon" />
        <span className="visually-hidden">Invert Image</span>
      </div>
    </button>

    <button
      className="image_tool_button"
      onClick={() => handleFilterChange("brightness")}
    >
      <div className="image_tool_icon_container">
        <LightIcon cssClass="image_tool_icon" />
        <span className="visually-hidden">Change Brightness</span>
      </div>
    </button>

    {showFullScreen && (
      <button
        className="image_tool_button"
        onClick={() => handleFilterChange("expand")}
      >
        <div className="image_tool_icon_container">
          <ExpandIcon cssClass="image_tool_icon" />
        </div>
      </button>
    )}
  </>
);

export { ImageDescription, ImageFilterTools, ImageTranslationTools };
