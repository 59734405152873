import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import PanZoom from "react-easy-panzoom";
import Modal from "components/modal/modal";
import CloseIcon from "resources/img/icons/close-icon.svg";
import {
  ImageDescription,
  ImageFilterTools,
  ImageTranslationTools,
} from "../ImageDescriptions";

const defaultFlip = { X: 1, Y: 1 };
const DocumentImageViewer = ({
  src,
  imageType,
  showFullScreen = true,
  hasFocus = false,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState("none");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [flip, setFlip] = useState(defaultFlip);
  const panZoom = useRef(null);
  const classes = classNames(`document_file ${filter}`);
  const zoomInSpeed = 3;
  const rotateAngle = 30;

  const handleKeyDown = useCallback((event) => {
    if (typeof props.handleKeyDown === "function") {
      props.handleKeyDown(event);
    }
    const keyActions = {
      f: () => showFullScreen && setShowModal((prev) => !prev),
      c: onReset,
      r: onRotateRight,
      l: onRotateLeft,
      z: onZoomIn,
      x: onZoomOut,
      h: () => onFlip({ X: flip.X * -1 }),
      v: () => onFlip({ Y: flip.Y * -1 }),
    };
    const action = keyActions[event.key];
    if (typeof action === "function") {
      action();
    }
  }, []);

  useEffect(() => {
    if (!panZoom.current || !panZoom.current?.container?.current) {
      return;
    }

    if (hasFocus) {
      panZoom?.current?.container?.current?.focus();
    }
    panZoom.current?.container?.current?.addEventListener(
      "keydown",
      handleKeyDown,
    );

    return () => {
      panZoom.current?.container?.current?.removeEventListener(
        "keydown",
        handleKeyDown,
      );
    };
  }, [props.panZoom, props.handleKeyDown, hasFocus]);

  const handleImageLoad = () => {
    setLoading(false);
    if (panZoom.current) {
      panZoom.current.autoCenter();
    }
  };

  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };

  const onZoomIn = () => panZoom.current && panZoom.current.zoomIn(zoomInSpeed);
  const onZoomOut = () =>
    panZoom.current && panZoom.current.zoomOut(zoomInSpeed);
  const onReset = () => {
    if (panZoom.current) {
      panZoom.current.autoCenter();
      setFlip(defaultFlip);
    }
  };
  const onRotateLeft = () =>
    panZoom.current &&
    panZoom.current.rotate((prevAngle) => prevAngle - rotateAngle);
  const onRotateRight = () =>
    panZoom.current &&
    panZoom.current.rotate((prevAngle) => prevAngle + rotateAngle);
  const handleFilterChange = (newFilter) => {
    if (newFilter === "expand") {
      setShowModal(true);
      setFilter("none");
      return;
    }
    setFilter((current) => (current === newFilter ? "none" : newFilter));
  };

  const onFlip = (newFlip) => {
    setFlip({ ...flip, ...newFlip });
  };

  // when flipping the image, we need to adjust the scale to keep the image in the same position
  // without doing this the image will move out of frame when dragging
  const unFlipX = flip.X === -1 ? flip.X * -1 : flip.X;
  const unFlipY = flip.Y === -1 ? flip.Y * -1 : flip.Y;

  return (
    <div className="document every_pixel">
      <DocumentReviewImageModal
        show={showModal}
        onClose={() => setShowModal(false)}
        src={src}
        imageType={imageType}
      />
      <ImageDescription descriptionType={imageType} />
      <section className="document_image_container">
        <PanZoom
          ref={panZoom}
          style={{
            transform: `scaleX(${unFlipX}) scaleY(${unFlipY})`,
          }}
          disableKeyInteraction={false}
          realPinch={false}
          className={classes}
          minZoom={0.5}
          noStateUpdate
          keyMapping={{
            87: { x: 0, y: -1, z: 0 },
            83: { x: 0, y: 1, z: 0 },
            65: { x: -1, y: 0, z: 0 },
            68: { x: 1, y: 0, z: 0 },
          }}
          {...props}
          onKeyDown={props.onKeyDown}
        >
          {loading && (
            <div className="loader loading-data-spinner loading-data-spinner--photo" />
          )}
          {error && <div>Error loading {imageType.replace(/_/, " ")}</div>}
          {!error && (
            <img
              style={{
                transform: `scaleX(${flip.X}) scaleY(${flip.Y})`,
              }}
              src={src}
              onLoad={handleImageLoad}
              onError={handleImageError}
              className="document_file_image"
              alt={imageType}
            />
          )}
        </PanZoom>
      </section>
      <section className="image_tool_container">
        <ImageTranslationTools
          onZoomIn={onZoomIn}
          onZoomOut={onZoomOut}
          onReset={onReset}
          onRotateLeft={onRotateLeft}
          onRotateRight={onRotateRight}
          onFlipX={() => onFlip({ X: flip.X * -1 })}
          onFlipY={() => onFlip({ Y: flip.Y * -1 })}
        />
        <ImageFilterTools
          showFullScreen={showFullScreen}
          handleFilterChange={handleFilterChange}
        />
      </section>
    </div>
  );
};

const DocumentReviewImageModal = ({ show, src, imageType, onClose }) => {
  const navigationRef = useRef(null);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Modal
      additionalClasses="modal new-styles document_review_image_modal"
      show={show}
    >
      <div ref={navigationRef}>
        <nav className="modal-navigation">
          <ul>
            <li>
              <button data-variant="ghost" type="button" onClick={onClose}>
                <img src={CloseIcon} alt="" />
                <span className="visually-hidden">Close Modal</span>
              </button>
            </li>
          </ul>
        </nav>
        <DocumentImageViewer
          showFullScreen={false}
          hasFocus
          src={src}
          enableBoundingBox
          imageType={imageType}
          onKeyDown={handleKeyDown}
        />
      </div>
    </Modal>
  );
};

DocumentImageViewer.displayName = "DocumentImageViewer";
DocumentReviewImageModal.displayName = "DocumentReviewImageModal";
DocumentImageViewer.Modal = DocumentReviewImageModal;

export default DocumentImageViewer;
