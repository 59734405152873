import React, { useState } from "react";
import JsonView from "@uiw/react-json-view";
import Modal from "./modal";

type JsonValue = string | number | boolean | null | JsonArray | JsonObject;
interface JsonArray extends Array<JsonValue> {}
interface JsonObject {
  [key: string]: JsonValue;
}

interface JsonModalProps {
  button?: boolean;
  name?: string;
  rawJson: JsonValue;
}

const JsonModal: React.FC<JsonModalProps> = ({
  button = true,
  name = "Prettify",
  rawJson,
}) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div style={{ overflow: "hidden" }}>
      {button ? (
        <button
          className="btn btn-primary button"
          onClick={() => setShow(!show)}
        >
          {name} <i className="fa fa-file-code-o" aria-hidden="true" />
        </button>
      ) : (
        <p className="btn btn-primary p" onClick={() => setShow(!show)}>
          {name} <i className="fa fa-file-code-o" aria-hidden="true" />
        </p>
      )}
      <Modal
        content={<JsonView value={rawJson} />}
        customStyle="modal--medium"
        onClose={() => setShow(false)}
        show={show}
      />
    </div>
  );
};

export default JsonModal;
