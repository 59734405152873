import { connect } from "react-redux";

import { fetchResources, removeReviewsState } from "actions/actions";
import Reviews from "components/reusable/reviews";
import { decode } from "util/route_util";

const mapStateToProps = (state) => ({
  reviews: state.reviews,
  userType: decode(localStorage.token).type,
  pagination: state.pagination,
  message: state.messages,
  errors: state.errors,
  partners: state.partners,
});

const mapDispatchToProps = (dispatch) => ({
  fetchResources: (table, sortObject) =>
    fetchResources(table, sortObject, dispatch),
  removeReviewsState: () => removeReviewsState(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
