import React, { useMemo, useCallback } from "react";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import TableHeaderContainer from "containers/reusable/table_header_container";

/**
 * Formats a date string into a human-readable format based on the specified or default locale.
 * @param {string} content The date string to be formatted. Should be in a format recognized
 * by the Date constructor (e.g., ISO 8601).
 * @param {string|undefined} [locale] The locale string that determines the formatting
 * of the date. If undefined, the default locale of the runtime environment is used.
 * @returns {string} A string representing the formatted date.
 */
const formatDate = (content, locale = undefined) =>
  new Date(content).toLocaleString(locale, {
    weekday: "short", // e.g., "Mon" for Monday
    year: "2-digit", // e.g., "20" for 2020
    month: "2-digit", // e.g., "04" for April
    day: "2-digit", // e.g., "05" for the 5th day of the month
    hour: "2-digit", // e.g., "13" for 1 PM
    minute: "2-digit", // e.g., "01" for 1 minute past the hour
    second: "2-digit", // e.g., "09" for 9 seconds past the minute
    timeZoneName: "short", // e.g., "GMT" for Greenwich Mean Time
  });

function Table({
  data,
  headers,
  tableType,
  fetchResources,
  redirect,
  clickableRow = false,
}) {
  if (!Array.isArray(data) || data.length === 0) {
    return <div>No {tableType.replace(/_/g, " ")} were found.</div>;
  }

  const formatCellContent = useCallback((content, sortField) => {
    if (sortField === "created_at") {
      return formatDate(content);
    }
    if (Array.isArray(content)) {
      return content.join(", ");
    }
    return content;
  }, []);

  const handleRowClick = useCallback(
    (row) => {
      if (tableType === "access_logs") {
        redirect(row);
      }
    },
    [redirect, tableType],
  );

  const tableRows = useMemo(
    () =>
      data.map((row, rowIndex) => {
        const cells = headers.map((header, cellIndex) => {
          const cellContent = row[header.sortField] ?? "";
          const formattedContent = formatCellContent(
            cellContent,
            header.sortField,
          );
          return <td key={`${rowIndex}-${cellIndex}`}>{formattedContent}</td>;
        });

        const rowClasses = classNames({
          "table-row--clickable": clickableRow,
          "table-row": !clickableRow,
        });

        return (
          <tr
            key={rowIndex}
            className={rowClasses}
            onClick={() => handleRowClick(row)}
          >
            {cells}
          </tr>
        );
      }),
    [data, headers, clickableRow, tableType, redirect, formatCellContent],
  );

  const tableHeaders = useMemo(
    () =>
      headers.map((header) => (
        <TableHeaderContainer
          key={uuidv4()}
          title={header.title}
          sortField={header.sortField}
          tableType={tableType}
          fetchResources={fetchResources}
        />
      )),
    [headers, tableType, fetchResources],
  );

  const tableClassName = classNames("table", {
    "dark-table": tableType === "companies",
    "admin-smile-references__table ref-table": tableType !== "companies",
  });

  return (
    <div>
      <table className={tableClassName}>
        <thead>
          <tr>{tableHeaders}</tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  );
}

export default Table;
export { formatDate };
