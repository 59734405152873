import React, { useRef } from "react";
import * as Sentry from "@sentry/react";
import classNames from "classnames";
import QRCode from "react-qr-code";
import IconQR from "assets/svgs/qr-icon.svg";
import PageHeader from "components/page_header/PageHeader";
import AddButton from "components/reusable/web_links/AddButton";
import ModalWrapper from "components/reusable/web_links/ModalWrapper";
import { StyledButton } from "components/reusable/web_links/StyledButton";
import {
  BodyItem,
  HeaderItem,
  Table,
  TableCaption,
  TableHeader,
  TableBody,
  TableRow,
} from "components/reusable/web_links/Table";
import {
  TableAction,
  TableActions,
} from "components/reusable/web_links/table_actions";
import TextInput from "components/reusable/web_links/TextInput";
import { getEnvironment } from "contexts/displayEnvironment";
import IconAdd from "resources/img/icons/addcircle.svg";
import IconCopy from "resources/img/icons/copy-icon.svg";
import IconDownload from "resources/img/icons/download-alt.svg";
import IconEdit from "resources/img/icons/pencil-icon.svg";
import IconRemove from "resources/img/icons/remove-icon.svg";
import {
  deleteApiKey,
  fetchAPIKeys,
  generateAPIKey,
  updateApiKey,
} from "util/api_util";
import {
  getAdminPartnerId,
  getUserPartnerID,
  getUserType,
} from "util/selectors";
import { Toast } from "util/Toast";
import styles from "./ApiKeyList.style.module.css";

interface ApiKey {
  api_key: string;
  created_at: string;
  created_by: string;
  deleted_at: string;
  deleted_by: string;
  id: string;
  last_used: string;
  name: string;
  status: string;
  updated_at: string;
  updated_by: string;
}

const ShowKeyModal: React.FC = ({ apiKey }) => {
  const copyKey = async () => {
    try {
      await navigator.clipboard.writeText(apiKey.api_key);
    } catch (err) {
      return Toast.fire({ title: "Unable to copy API key." });
    }
    Toast.fire({ title: "API key copied." });
  };
  return (
    <div>
      <div>
        <strong>{apiKey?.name}</strong>
        <p id="api-key">{apiKey?.api_key}</p>
        <span className="pointer" onClick={copyKey}>
          <img
            src={IconCopy}
            alt="copy-icon"
            className="icon--small"
            data-testid="copy-icon"
          />{" "}
          Copy
        </span>
      </div>
    </div>
  );
};

const EditKeyModal: React.FC = ({ apiKey, isOpen, onClose, onSuccess }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>(apiKey?.name || "");

  if (getAdminPartnerId()) {
    Toast.fire({
      title: "Admins cannot edit API keys on behalf of a partner.",
    });
    onClose();
    return;
  }

  const closeModal = () => {
    setName("");
    setLoading(false);
    onClose();
  };
  const updateName = async () => {
    setLoading(true);
    const resp = await updateApiKey({ id: apiKey.id, name });
    if (resp.error) {
      Toast.fire({ title: resp.error });
      setLoading(false);
    } else {
      Toast.fire(resp.message);
      onSuccess();
      closeModal();
    }
  };

  const handleChange = (newValue) => {
    setName(newValue);
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      hideBackButton
      title="Edit Credential"
      titleClass={classNames("font-bold", styles["modal-title"], "text-xl")}
    >
      <div>
        <div>
          <label>Existing Name</label>
          <p>{apiKey.name}</p>
          <TextInput
            label="API Key Name"
            placeholder={name || "API Key Name"}
            type="text"
            value={name}
            dataTestId="name"
            onChange={handleChange}
          />
          <div className={styles["modal-buttons"]}>
            <StyledButton
              className="button"
              variant="outline"
              dataTestId="edit-cancel"
              disabled={loading}
              onClick={closeModal}
            >
              Cancel
            </StyledButton>
            <StyledButton
              className="button"
              variant="outline"
              dataTestId="edit-save"
              disabled={loading}
              onClick={updateName}
            >
              Save
            </StyledButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

const DeleteKeyModal: React.FC = ({ apiKey, isOpen, onClose, onSuccess }) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  if (getAdminPartnerId()) {
    Toast.fire({
      title: "Admins cannot delete API keys on behalf of a partner.",
    });
    onClose();
    return;
  }

  const deleteKey = async () => {
    setLoading(true);
    const resp = await deleteApiKey({ id: apiKey.id });
    if (resp.error) {
      Toast.fire({ title: resp.error });
      setLoading(false);
    } else {
      Toast.fire({ title: "API key deleted." });
      onSuccess();
      onClose();
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      hideBackButton
      title="Delete credential"
      titleClass={classNames("font-bold", styles["modal-title"], "text-xl")}
    >
      <div>
        <div>
          <p>
            <strong>Are you sure you want to revoke this key?</strong>
          </p>
          <p>This cannot be undone.</p>
          <p>
            All mobile app and services using {apiKey.name} will stop working.
          </p>
          <div className={styles["modal-buttons"]}>
            <StyledButton
              className="button"
              variant="outline"
              onClick={onClose}
              dataTestId="delete-cancel"
              disabled={loading}
            >
              Cancel
            </StyledButton>
            <StyledButton
              className="button button--red"
              variant="primary"
              onClick={deleteKey}
              dataTestId="delete-revoke"
              disabled={loading}
            >
              Revoke
            </StyledButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

const AddKeyModal: React.FC = ({ isOpen, onClose, onProd, onSuccess }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");

  if (getAdminPartnerId()) {
    Toast.fire({
      title: "Admins cannot create API keys on behalf of a partner.",
    });
    onClose();
    return;
  }
  const createKey = async () => {
    setLoading(true);
    const resp = await generateAPIKey({ name });
    if (resp.error) {
      Toast.fire({ title: resp.error });
      setLoading(false);
    } else {
      onSuccess();
      Toast.fire({ title: "API key added." });
      onClose();
    }
  };
  const handleChange = (newName) => {
    setName(newName);
  };
  const onCloseWrapper = () => {
    setLoading(false);
    setName("");
    onClose();
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onCloseWrapper}
      hideBackButton
      title={`Create a ${onProd ? "Production" : "Sandbox"} Credential`}
      titleClass={classNames("font-bold", styles["modal-title"], "text-xl")}
    >
      <div>
        <div>
          <TextInput
            type="text"
            value={name}
            dataTestId="name"
            onChange={handleChange}
            placeholder="API Key Name"
            label="API Key Name"
          />
          <div className={styles["modal-buttons"]}>
            <StyledButton
              className="button modal-button"
              variant="outline"
              dataTestId="add-cancel"
              disabled={loading}
              onClick={onCloseWrapper}
            >
              Cancel
            </StyledButton>
            <StyledButton
              className="button modal-button"
              isActive
              variant="primary"
              dataTestId="add-confirm"
              disabled={loading}
              onClick={createKey}
            >
              Confirm
            </StyledButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

const ApiKeyList: React.FC = () => {
  const [adminPartnerId] = React.useState<string | undefined>(
    getAdminPartnerId(),
  );
  const [apiKey, setOpenKeyModal] = React.useState<string | undefined>(
    undefined,
  );
  const [apiKeys, setApiKeys] = React.useState<ApiKey[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openAddModal, setOpenAddModal] = React.useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<
    object | undefined
  >(undefined);
  const [openEditModal, setOpenEditModal] = React.useState<object | undefined>(
    undefined,
  );
  const [openQrModal, setOpenQrModal] = React.useState<object | undefined>(
    undefined,
  );
  const [userType] = React.useState<object>(getUserType);
  const onProd = getEnvironment() === "production";
  const abortControllerRef = useRef(new AbortController());

  const getAbortController = () => {
    const abortController = abortControllerRef?.current;
    if (!abortController) {
      abortControllerRef.current = new AbortController();
    } else if (abortController && !abortController.signal.aborted) {
      abortController.abort({ message: "aborted" });
      abortControllerRef.current = new AbortController();
    }
    return abortControllerRef;
  };

  React.useEffect(() => {
    if (typeof onProd !== "boolean") {
      return;
    }
    getData();
  }, [onProd]);

  const getPartnerId = () =>
    userType === "admin" && adminPartnerId
      ? adminPartnerId
      : getUserPartnerID();

  const getData = async () => {
    const partnerId = getPartnerId();
    const resp = await fetchAPIKeys(
      { partnerId },
      getAbortController().current,
    ).catch((e) => {
      if (e.name !== "AbortError") {
        console.error(e);
        Sentry.captureException(e);
      }
    });

    if (resp?.api_keys) {
      setApiKeys(resp.api_keys);
    } else if (resp?.error) {
      Toast.fire({ title: resp.error });
    }
    setLoading(false);
  };

  const renderKeymodal = (key) => {
    setOpenKeyModal(key);
  };

  const renderEditModal = (key) => {
    setOpenEditModal(key);
  };

  const renderDeleteModal = (key) => {
    setOpenDeleteModal(key);
  };

  const buildConfig = (key) => {
    const data = {
      auth_token: key.encrypted_partner_id,
      partner_id: getPartnerId(),
      prod_lambda_url: "https://api.smileidentity.com/v1/",
      prod_url: "",
      test_lambda_url: "https://testapi.smileidentity.com/v1/",
      test_url: "",
      version: "1.0.0",
    };
    return data;
  };

  const downloadConfig = (key) => {
    const data = buildConfig(key);
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(data, null, 2)], {
      type: "text/json",
    });
    element.href = URL.createObjectURL(file);
    element.download = "smile_config.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const qrCodeModalContent = (key) => {
    const config = JSON.stringify(buildConfig(key));
    return (
      <div className="new-styles">
        <div className={styles["qr-code"]}>
          <div>
            <QRCode value={config} size={200} viewBox="0 0 200 200" />
          </div>
        </div>
        <div className={styles["qr-code-text"]}>
          <p>
            To use this QR code, open your Smile sample app and follow the
            prompts.
          </p>
        </div>
      </div>
    );
  };

  const closeKeyModal = () => {
    setOpenKeyModal(undefined);
  };

  const closeEditModal = async () => {
    setOpenEditModal(undefined);
    await getData();
  };

  const closeDeleteModal = async () => {
    setOpenDeleteModal(undefined);
    await getData();
  };

  const closeAddModal = async () => {
    setOpenAddModal(false);
    await getData();
  };

  const getDate = (date) =>
    new Date(date).toLocaleString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

  const getTime = (date) =>
    new Date(date).toLocaleString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });

  const renderApiKeys = () =>
    apiKeys?.map((key) => (
      <TableRow key={key.id}>
        <BodyItem>{key.name}</BodyItem>
        <BodyItem className={classNames("new-styles", styles["obscured-key"])}>
          <span className={styles["obscured-key-text"]}>
            {key.api_key.slice(0, 5)}...
          </span>
          <StyledButton
            onClick={() => renderKeymodal(key)}
            className="button"
            variant="outline"
            dataTestId={`view-${key.id}`}
          >
            View
          </StyledButton>
        </BodyItem>
        <BodyItem>{key.created_by}</BodyItem>
        <BodyItem>
          <div>
            <p>{getDate(key.created_at)}</p>
            <p>{getTime(key.created_at)}</p>
          </div>
        </BodyItem>
        <BodyItem>
          <div>
            <p>{key.last_used && getDate(key.last_used)}</p>
            <p>{key.last_used && getTime(key.last_used)}</p>
          </div>
        </BodyItem>
        <BodyItem>
          <TableActions className="pointer">
            <TableAction
              onClick={() => renderDeleteModal(key)}
              icon={IconRemove}
              label="Remove"
              labelClassName="action-buttons"
              alt="remove icon"
              className="icon--small"
              dataTestId={`remove-${key.id}`}
              disabled={adminPartnerId || loading}
            />
            <TableAction
              onClick={() => renderEditModal(key)}
              icon={IconEdit}
              label="Edit"
              alt="edit icon"
              className="icon--small"
              dataTestId={`edit-${key.id}`}
              disabled={adminPartnerId || loading}
            />
            <TableAction
              onClick={() => downloadConfig(key)}
              icon={IconDownload}
              label="Config"
              alt="download icon"
              className="icon--small"
              dataTestId={`download-${key.id}`}
            />
            <TableAction
              onClick={() => setOpenQrModal(key)}
              icon={IconQR}
              label="QR"
              alt="qr icon"
              className="icon--small"
              dataTestId={`qr-${key.id}`}
            />
          </TableActions>
        </BodyItem>
      </TableRow>
    ));

  return (
    <div className={styles["new-styles-container"]}>
      <PageHeader
        title={
          <div>
            <span
              className={styles[`selected--${onProd ? "green" : "orange"}`]}
            >
              {onProd ? "Production" : "Sandbox"}
            </span>{" "}
            Credentials
          </div>
        }
      />
      {openAddModal && (
        <AddKeyModal
          isOpen={openAddModal}
          hideBackButton
          onClose={closeAddModal}
          onSuccess={getData}
          onProd={onProd}
        />
      )}
      {openDeleteModal && (
        <DeleteKeyModal
          apiKey={openDeleteModal}
          isOpen={openDeleteModal}
          onClose={closeDeleteModal}
          onSuccess={getData}
          hideBackButton
        />
      )}
      {openEditModal && (
        <EditKeyModal
          apiKey={openEditModal}
          isOpen={openEditModal}
          onClose={closeEditModal}
          onSuccess={getData}
        />
      )}
      {openQrModal && (
        <ModalWrapper
          isOpen={openQrModal}
          hideBackButton
          title="QR Code"
          onClose={() => setOpenQrModal(undefined)}
          titleClass={classNames("font-bold", styles["modal-title"], "text-xl")}
        >
          {qrCodeModalContent(openQrModal)}
        </ModalWrapper>
      )}
      {apiKey && (
        <ModalWrapper isOpen={apiKey} hideBackButton onClose={closeKeyModal}>
          <ShowKeyModal
            apiKey={apiKeys.find((key) => key.id === apiKey?.id)}
            onClose={closeKeyModal}
          />
        </ModalWrapper>
      )}
      <div className="document-list">
        <Table>
          <TableCaption>
            <p>
              Each credential you generate includes three distinct components:
            </p>
            <ul>
              <li>
                <strong>API Key</strong> for creating signatures to authenticate
                your API requests
              </li>
              <li>
                A <strong>Config</strong> file to authorize requests from our
                Mobile SDKs.
              </li>
              <li>
                A <strong>QR</strong> code that can be scanned to set up the
                Sample App.
              </li>
            </ul>
          </TableCaption>
          <TableHeader>
            <HeaderItem>Name</HeaderItem>
            <HeaderItem>Key</HeaderItem>
            <HeaderItem>Author</HeaderItem>
            <HeaderItem>Created</HeaderItem>
            <HeaderItem>Last Used</HeaderItem>
            <HeaderItem>Actions</HeaderItem>
          </TableHeader>
          <TableBody>{renderApiKeys()}</TableBody>
        </Table>
        <AddButton
          label={`Create ${onProd ? "Production" : "Sandbox"} Credential`}
          wrapperClassName={styles["justify-center"]}
          onClick={() => setOpenAddModal(true)}
          icon={IconAdd}
          alt="add-icon"
          dataTestId="add-icon"
          disabled={adminPartnerId || loading}
        />
      </div>
    </div>
  );
};

export default ApiKeyList;
export { AddKeyModal, DeleteKeyModal, EditKeyModal, ShowKeyModal };
