import React, { useState } from "react";
import Continue from "assets/svgs/continue.svg";
import { createInvoice } from "util/api_util";

function PayNow({ createInvoice }) {
  const MIN_AMOUNT = 20;
  const [amount, setAmount] = useState();
  const formStates = ["idle", "submitting", "error"];
  const [formState, setFormState] = useState(formStates[0]);
  const [error, setError] = useState();

  const onChange = (event) => {
    if (parseFloat(event.target.value)) {
      setAmount(event.target.value);
    }
  };

  const makePayment = async (event) => {
    event.preventDefault();
    setFormState("submitting");

    try {
      if (parseFloat(amount) < MIN_AMOUNT) {
        const errorMessage = "$20 is the minimum funding amount";
        throw new Error(errorMessage);
      }

      const payload = {
        currency: "USD",
        partner_id: localStorage.adminPartnerId,
        amount,
      };

      const response = await createInvoice(payload);

      if (response?.checkout_url) {
        window.open(response.checkout_url, "_self");
      }
    } catch (error) {
      setFormState("error");
      setError(error.message);
    }
  };

  return (
    <div className="stack">
      <h1 className="font-heading">Top up your wallet to get started</h1>
      <form
        style={{ maxInlineSize: "15rem" }}
        onSubmit={makePayment}
        className="stack"
      >
        <div className="input-group">
          <label htmlFor="amount">Amount (in USD)</label>
          <input
            type="text"
            inputMode="numeric"
            value={amount}
            disabled={formState === "submitting"}
            onChange={onChange}
            name="amount"
            id="amount"
            pattern="\d+"
          />
        </div>

        {formState === "error" && <p className="color-fail">{error}</p>}

        <p style={{ textAlign: "right" }}>
          <button
            type="submit"
            disabled={formState === "submitting"}
            data-variant="primary"
          >
            <img src={Continue} alt="" />
            <span>Proceed to Payment</span>
          </button>
        </p>
      </form>
    </div>
  );
}

PayNow.defaultProps = {
  createInvoice,
};

export default PayNow;
