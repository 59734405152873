import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "components/reusable/header";
import { Loader } from "components/reusable/Loader";
import SmileLock from "resources/img/brand/smile-id__symbol--white.svg";
import { signUp, getInvitation } from "util/api_util";

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invitationID: this.props.match.params.invitation_id,
      email: undefined,
      password: undefined,
      confirmPassword: undefined,
      name: undefined,
      disabled: false,
      message: "",
      messageType: undefined,
      formIsValid: false,
      formErrors: {
        name: undefined,
        password: {
          lowercase: false,
          uppercase: false,
          special: false,
          number: false,
          minimum: false,
        },
        confirmPassword: undefined,
      },
      redirect: null,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    getInvitation(this.state.invitationID).then((resp) => {
      if (!resp.errors) {
        if (resp.name || resp.email) {
          this.setState({
            email: resp.email,
            name: resp.name,
          });
        } else {
          this.setState({
            messageType: "error",
            message:
              "Invitation not found. Please contact your team's administrator.",
          });
        }
      } else {
        this.setState({
          messageType: "error",
          message: resp.errors[0],
        });
      }
    });
  }

  onInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.validateField(e.target.name, e.target.value);
    this.setState({
      formIsValid: this.checkIfFormValid(),
      disabled: !this.checkIfFormValid(),
    });
  }

  checkIfFormValid() {
    const { formErrors } = this.state;

    for (const validation in formErrors.password) {
      if (!formErrors.password[validation]) {
        return false;
      }
    }

    if (
      formErrors.confirmPassword ||
      formErrors.confirmPassword === undefined
    ) {
      return false;
    }

    return true;
  }

  validateField(name, value) {
    const password = {};
    const specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    const { formErrors } = this.state;

    if (name === "name") {
      if (value.length <= 0) {
        formErrors.name = "Please enter your name.";
      } else {
        formErrors.name = null;
      }
    }

    if (name === "password") {
      password.lowercase = value.search(/[a-z]/) >= 0;
      password.uppercase = value.search(/[A-Z]/) >= 0;
      password.special = specialChars.test(value);
      password.number = value.search(/[0-9]/) >= 0;
      password.minimum = value.length >= 8;
      formErrors.password = password;
    }

    if (name === "confirmPassword") {
      if (this.state.password !== value) {
        formErrors.confirmPassword = "Passwords must match.";
      } else {
        formErrors.confirmPassword = null;
      }
    }

    this.setState({
      formErrors,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.setState({ disabled: true, loading: true });

    const data = {
      email: this.state.email,
      name: this.state.name,
      password: this.state.confirmPassword,
      invitation_id: this.state.invitationID,
    };

    // TODO: use the errors from the servers
    signUp(data).then((resp) => {
      if (resp.errors) {
        this.setState({
          message: resp.errors[0],
          messageType: "error",
          disabled: false,
          loading: false,
        });
      } else {
        this.setState({
          redirect: "/",
          message: "Password saved.",
          messageType: "success",
        });
      }
    });
  }

  render() {
    const textErrors = <div className="errors">{this.state.error}</div>;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <Header envSlider={false} />
        <div className="newsmile-container__create-password">
          <div className="smile-create-password__container">
            <div className="smile-create-password__icon-container">
              <img
                className="smile-create-password__icon"
                src={SmileLock}
                alt="logo"
              />
            </div>
            <div className="smile-create-password__heading">
              {" "}
              Hi {this.state.name || ""}, please complete your account setup
            </div>

            {textErrors}
            <div
              className={`message message--create-password ${
                this.state.messageType === "success"
                  ? "message--success"
                  : "message--error"
              }`}
              style={{
                display: this.state.message ? "block" : "none",
              }}
            >
              {this.state.message}
            </div>

            <form
              className="smile-form smile-form--create-password"
              id="new_account"
            >
              <div className="field">
                <br />
                <label> Password </label>
                <input
                  autoComplete="off"
                  type="password"
                  id="password"
                  name="password"
                  className="input--line"
                  onChange={this.onInputChange}
                  value={this.state.password}
                />

                <br />
                <br />

                <div
                  className={
                    this.state.formErrors.password.lowercase
                      ? "success"
                      : "failure"
                  }
                >
                  {" "}
                  - One lowercase letter{" "}
                </div>
                <div
                  className={
                    this.state.formErrors.password.uppercase
                      ? "success"
                      : "failure"
                  }
                >
                  {" "}
                  - One uppercase letter{" "}
                </div>
                <div
                  className={
                    this.state.formErrors.password.special
                      ? "success"
                      : "failure"
                  }
                >
                  {" "}
                  - One special character{" "}
                </div>
                <div
                  className={
                    this.state.formErrors.password.number
                      ? "success"
                      : "failure"
                  }
                >
                  {" "}
                  - One number{" "}
                </div>
                <div
                  className={
                    this.state.formErrors.password.minimum
                      ? "success"
                      : "failure"
                  }
                >
                  {" "}
                  - 8 characters minimum{" "}
                </div>
              </div>

              <div className="field">
                <br />
                <label> Confirm Password </label>
                <input
                  autoComplete="off"
                  type="password"
                  id="password"
                  name="confirmPassword"
                  className="input--line"
                  onChange={this.onInputChange}
                  value={this.state.confirmPassword}
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display: this.state.formErrors.confirmPassword
                      ? "block"
                      : "none",
                  }}
                >
                  {this.state.formErrors.confirmPassword}
                </div>
              </div>

              <br />
              <div
                className="button-container--center"
                onClick={this.passwordLogin}
              >
                <button
                  className="btn btn-primary smile-login__button"
                  disabled={this.state.disabled}
                  type="submit"
                  onClick={this.onFormSubmit}
                >
                  {this.state.loading && <Loader />}
                  &nbsp;&nbsp;Create Account
                </button>
              </div>
            </form>
            <div className="fixed-dots--bottom-across" />
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
