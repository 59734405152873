export const RESULTS_HEADERS = [
  { title: "Ref ID", sortField: "ref_id", style: "first" },
  { title: "Job ID", sortField: "smile_job_id" },
  { title: "Created At", sortField: "created_at" },
];

export const REVIEWS_HEADERS = [
  { title: "First sent at", sortField: "first_sent_at" },
  { title: "Created at", sortField: "created_at" },
];

export const ACCESS_LOGS_HEADERS = [
  { title: "Created at", sortField: "created_at" },
  { title: "Partner ID", sortField: "partner_id" },
  { title: "Job ID", sortField: "job_id" },
  { title: "Job Type", sortField: "ran_as_job_type" },
  { title: "Result Code", sortField: "result_code" },
  { title: "Result Text", sortField: "result_message" },
  { title: "Charges", sortField: "amount" },
  { title: "SDK", sortField: "sdk" },
];

export const COMPANIES_HEADERS = [
  { title: "Partner ID", sortField: "partner_id" },
  { title: "Name", sortField: "partner_name" },
  { title: "Production Status", sortField: "production_enabled" },
  { title: "Wallet Balance", sortField: "wallet_balance" },
  { title: "Wallet History", sortField: "billing" },
  { title: "Billing Info", sortField: "pricing_plan" },
  { title: "Edit", sortField: "edit" },
];

export const STATS_HEADERS = [
  { title: "Status", sortField: "status" },
  { title: "Number of Observations", sortField: "count" },
  { title: "Mean", sortField: "mean" },
  { title: "Standard Deviation", sortField: "standard_deviation" },
  { title: "Variance", sortField: "variance" },
  { title: "Maximum", sortField: "max" },
  { title: "Median", sortField: "median" },
  { title: "Minimum", sortField: "min" },
];

export const COMPARE_REVIEWS_HEADERS = [
  { title: "Job ID", sortField: "job_id" },
  { title: "Partner Id", sortField: "job.partner_id" },
  { title: "Job Type", sortField: "job.partner_params.job_type" },
  { title: "Date", sortField: "job.created_at" },
  { title: "Job Duration", sortField: "review.latest_updated_review_result" },
  {
    title: "Review Duration",
    sortField: "review.latest_updated_review_result",
  },
  { title: "Reviewer", sortField: "author.email" },
  {
    title: "Machine Conf Value",
    sortField: "smile_reference.result.IsMachineResult",
  },
  {
    title: "Human Conf Value",
    sortField: "smile_reference.result.ConfidenceValue",
  },
  { title: "Human Result", sortField: "smile_reference.result.ResultText" },
];

export const SPOOF_REVIEWS_HEADERS = [
  { title: "Job ID", sortField: "job_id" },
  { title: "Partner Id", sortField: "job.partner_id" },
  { title: "Job Type", sortField: "job.partner_params.job_type" },
  { title: "Date", sortField: "job.created_at" },
  { title: "Job Duration", sortField: "review.latest_updated_review_result" },
  {
    title: "Review Duration",
    sortField: "review.latest_updated_review_result",
  },
  { title: "Reviewer", sortField: "author.email" },
  { title: "Human Result", sortField: "smile_reference.result.ResultText" },
];

export const KYC_REVIEWS_HEADERS = [
  {
    title: "Company",
    fieldName: "legal_name",
    filterable: false,
    sortable: true,
  },
  {
    title: "Partner ID",
    fieldName: "partner_id",
    filterable: false,
    sortable: false,
  },
  {
    title: "Country",
    fieldName: "country",
    filterable: false,
    sortable: true,
  },
  {
    title: "Submitted",
    fieldName: "updated_at",
    filterable: false,
    sortable: true,
  },
  {
    title: "Approval Status",
    fieldName: "kyc_state",
    filterable: false,
    filterOptions: [
      {
        label: "Pending",
        value: "submitted",
      },
      {
        label: "Approved",
        value: "accepted",
      },
      {
        label: "Rejected",
        value: "rejected",
      },
    ],
    sortable: true,
  },
  {
    title: "Contact",
    fieldName: "contact_email",
    filterable: false,
    sortable: false,
  },
];

export const REVIEW_PERMISSIONS_HEADERS = [
  {
    title: "Email",
    sortField: "email",
    sortable: false,
  },
  {
    title: "Name",
    sortField: "name",
    sortable: false,
  },
  {
    title: "Permissions",
    sortField: "review_permissions",
    sortable: false,
  },
];

export const nonSortableHeaders = [
  "Show",
  "Run the Job Again",
  "Download CSV",
  "Delete",
  "Completed",
  "Smile Reference Count",
  "Algorithms",
  "Completed Algorithms",
  "Add to JSON",
  "Download Basic CSV",
  "Ref ID",
  "Job ID",
  "Created At",
];
