import React from "react";
import classNames from "classnames";
import Header from "components/reusable/header";
import SmileLock from "resources/img/brand/smile-id__symbol--96x96.svg";
import { forgotPassword } from "util/api_util";

function ForgotPasswordPage() {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageType, setMessageType] = React.useState("");

  const onForgotPassword = (e) => {
    e.preventDefault();
    forgotPassword({ email }).then((resp) => {
      if (!resp.error) {
        setMessage(resp.message);
        setMessageType("success");
      } else {
        setMessage(resp.error);
        setMessageType("error");
      }
    });
  };

  const msgClass = classNames("message message--reset-password", {
    "message--success": messageType === "success",
    "message--error": messageType === "error",
  });

  return (
    <div>
      <Header envSlider={false} />
      <div className="newsmile-container__login">
        <div className="smile-login__container">
          <div className="smile-login__icon-container">
            <img
              className="smile-login__icon"
              src={SmileLock}
              alt="smile logo"
            />
          </div>

          <div
            className={msgClass}
            style={{
              display: message ? "block" : "none",
            }}
          >
            {message}
          </div>

          <div className="smile-login__heading">Forgot your password</div>

          <form
            className="smile-form smile-form--login"
            id="new_account"
            onSubmit={onForgotPassword}
          >
            <div className="field">
              <br />
              <label>Email Address</label>
              <input
                autoFocus
                type="email"
                id="email"
                name="email"
                className="input--line"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>

            <br />

            <div
              className="button-container--center"
              onClick={onForgotPassword}
            >
              <button
                className="btn btn-primary smile-login__button"
                type="button"
              >
                Submit
              </button>
            </div>

            <div className="fixed-dots--bottom-across" />
          </form>
        </div>
      </div>
    </div>
  );
}
export default ForgotPasswordPage;
