import React, { Component } from "react";
import moment from "moment";
import Select from "react-select";
import CountryPicker from "components/reusable/country_picker";
import dateIcon from "resources/img/icons/date-icon.svg";
import {
  buildCleanUrl,
  fetchFundingHistory,
  fetchUsageStatementURLs,
  fetchWallet,
} from "util/api_util";
import {
  convertCurrency,
  formatCurrency,
  formatLocalisedDate,
} from "util/format_helpers";
import { canUserView } from "util/route_util";
import { getMonthsOptions } from "util/web_app_helpers";
import Breadcrumbs from "../../breadcrumbs";
import styles from "./wallet_overview.module.css";

class BillingHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      loading: true,
      currency: sessionStorage.getItem("currency") || "USD",
      usageStatementMonths: "",
      usageStatementLinksExpired: true,
      selectedUsageStatementMonth: "",
      dateToggle: false,
      walletTransactionMonths: {},
      selectedWalletTransactionMonth: "",
    };
    this.redirectTo = this.redirectTo.bind(this);
    this.setCurrency = this.setCurrency.bind(this);
    this.convertCurrency = convertCurrency.bind(this);
    this.fundWallet = this.fundWallet.bind(this);
    this.getUsageStatements = this.getUsageStatements.bind(this);
  }

  componentDidMount() {
    sessionStorage.setItem("url", `/partner/billing/history`);
    const partner_id = this.getPartnerId();
    fetchWallet({ partner_id }).then((resp) => {
      this.setState({
        walletBalance: resp.wallet_balance,
        exchangeRates: resp.exchange_rates,
      });
    });
    this.getUsageStatements();
    this.getHistory();
  }

  getPartnerId() {
    let { partner_id } = this.props.match.params;
    if (localStorage.adminPartnerId) {
      partner_id = localStorage.adminPartnerId;
    }
    return partner_id;
  }

  getHistory() {
    const json = {
      funding_only: true,
      currency: "USD",
      partner_id: this.getPartnerId(),
    };
    fetchFundingHistory(json).then((resp) => {
      if (!resp.error) {
        this.setState({
          history: resp.wallet_transactions,
          loading: false,
        });

        this.setState({
          walletTransactionMonths: getMonthsOptions(),
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  getUsageStatements() {
    fetchUsageStatementURLs({ partner_id: this.getPartnerId() }).then(
      (resp) => {
        const getMonths = () => {
          const monthsOptions = [];
          for (let i = 1; i <= 6; i += 1) {
            const date = moment().subtract(i, "months");
            let formattedDate = date.format("MMMM, YYYY");
            if (resp[formattedDate] === "") {
              formattedDate = formattedDate.concat(" - No usage");
            }
            monthsOptions.push({
              value: formattedDate,
              label: formattedDate,
              isDisabled: !resp[formattedDate],
            });
          }
          return monthsOptions;
        };
        const usageStatementMonths = getMonths();
        this.setState({
          usageStatements: resp,
          usageStatementLinksExpired: false,
          usageStatementMonths,
        });

        setTimeout(() => {
          this.setState({
            usageStatementLinksExpired: true,
          });
        }, 3600000);
      },
    );
  }

  setCurrency(currency) {
    this.setState({
      currency,
    });
  }

  // TODO: this event is in multiple files - move it to a common helper
  redirectTo(event) {
    sessionStorage.setItem("url", event.target.dataset.value);
    this.props.history.push(event.target.dataset.value);
  }

  tableRows() {
    if (this.state.loading) {
      return (
        <div className="loader__container loader__billing-history-container">
          <div className="loader loading-data-spinner" />
        </div>
      );
    }
    if (this.state.history.length > 0) {
      return this.state.history.map((transaction) => (
        <tr key={transaction.Date}>
          <td>
            {formatLocalisedDate(
              moment(transaction.Date),
              "YYYY-MM-DD HH:MM:ss",
            )}
          </td>
          <td>
            {formatCurrency(
              parseFloat(convertCurrency(this, transaction.Amount)),
              this.state.currency,
            )}
          </td>
          <td>{transaction.Email}</td>
          <td>{transaction.Category}</td>
          <td>
            {formatCurrency(
              parseFloat(convertCurrency(this, transaction["Wallet Balance"])),
              this.state.currency,
            )}
          </td>
        </tr>
      ));
    }
    return (
      <tr>
        <td>
          You have yet to fund your wallet.
          {canUserView(["/partner/billing/payment"]) && (
            <button
              className="btn btn-primary btn__test-cases"
              onClick={this.fundWallet}
            >
              Fund Wallet
            </button>
          )}
        </td>
      </tr>
    );
  }

  fundWallet() {
    this.props.history.push(
      window.location.pathname.replace("history", "payment"),
    );
  }

  render() {
    const downloadTransactions = () => {
      const [month, year] =
        this.state.selectedWalletTransactionMonth.value.split(",");

      const date = moment().month(month);
      date.set("year", year);

      const from = date
        .startOf("month")
        .startOf("day")
        .format("YYYY-MM-DDThh:mm:ss");
      const to = date.endOf("month").endOf("day").format("YYYY-MM-DDThh:mm:ss");

      const params = {
        start_date: from,
        end_date: to,
        currency: this.state.currency,
        token: localStorage.token,
        partner_id: this.getPartnerId(),
      };
      const downloadLink = buildCleanUrl(`invoices/wallet_history`, params);
      window.open(downloadLink, "_blank");
    };

    const downloadUsageStatements = () => {
      window.open(
        this.state.usageStatements[
          this.state.selectedUsageStatementMonth.value
        ],
        "_blank",
      );
    };

    let walletBalance = 0;
    let tableRows;

    if (this.state.exchangeRates) {
      walletBalance = convertCurrency(this, this.state.walletBalance);
      tableRows = this.tableRows();
    }

    return (
      <div className="billing-history">
        <Breadcrumbs
          links={[
            { href: "/partner/billing", title: "Billing" },
            { href: "/partner/billing/history", title: "Wallet History" },
          ]}
        />
        <div className="billing-history__currency">
          <CountryPicker
            callback={this.setCurrency}
            currency={this.state.currency}
          />
        </div>

        <div className={styles.walletOverview}>
          <h2>Wallet Overview</h2>
          <div className={styles.currentBalanceContainer}>
            <div className={styles.balance}>
              {formatCurrency(parseFloat(walletBalance), this.state.currency)}
            </div>
            <div className={styles.currentBalanceCopy}>
              {this.state.history.length > 0 && (
                <>
                  <h4>Current Balance</h4>
                  <p>
                    Last payment occurred on{" "}
                    {moment(this.state.history[0].Date).format("MMMM DD, YYYY")}
                  </p>
                </>
              )}
              {this.state.currency !== "USD" && (
                <p className={styles.fundWalletFootnote}>
                  We record wallet balances in US dollars and display the
                  equivalent in local currency at the official exchange rate.
                </p>
              )}
            </div>
            <div className={styles.fundWallet}>
              {canUserView(["/partner/billing/payment"]) && (
                <button className="btn btn-primary" onClick={this.fundWallet}>
                  Fund Wallet
                </button>
              )}
            </div>
          </div>

          <div className={styles.downloadsContainer}>
            <div className={styles.transactionHistory}>
              <div className={styles.downloadHeader}>
                <h3>Transaction History</h3>
                <span>CSV</span>
              </div>
              <div className={styles.selectContainer}>
                <div className={styles.select}>
                  <Select
                    selectedValue={this.state.selectedWalletTransactionMonth}
                    options={this.state.walletTransactionMonths}
                    onChange={(option) => {
                      this.setState({
                        selectedWalletTransactionMonth: option,
                      });
                    }}
                    placeholder="Select a month"
                    prefixIcon={dateIcon}
                  />
                </div>
                <button
                  className="btn btn-primary"
                  disabled={this.state.selectedWalletTransactionMonth === ""}
                  onClick={downloadTransactions}
                >
                  Download
                </button>
              </div>
            </div>
            <div className={styles.usageStatement}>
              <div className={styles.downloadHeader}>
                <h3>Usage Statement</h3>
                <span>PDF</span>
              </div>
              <div className={styles.selectContainer}>
                <div className={styles.select}>
                  <Select
                    options={this.state.usageStatementMonths}
                    disabled={this.state.usageStatementLinksExpired}
                    onChange={(option) =>
                      this.setState({
                        selectedUsageStatementMonth: option,
                      })
                    }
                    selectedValue={this.state.selectedUsageStatementMonth}
                    placeholder="Select a month"
                    prefixIcon={dateIcon}
                  />
                </div>
                <button
                  className="btn btn-primary"
                  onClick={downloadUsageStatements}
                  disabled={!this.state.selectedUsageStatementMonth}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="error">{this.state.error}</p>
        <div className="funding-history__container">
          <h2>Funding History</h2>
          <p>Keep track of wallet funding details.</p>
          <table className="smile-table billing-history__table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Total Amount</th>
                <th>User</th>
                <th>Category</th>
                <th>Wallet Balance</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default BillingHistory;
