import React, { useContext, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { Redirect, Route, useLocation, withRouter } from "react-router-dom";
import { DisplayEnvironment } from "contexts/displayEnvironment";

export const decode = (token) => {
  try {
    return jwtDecode(token);
  } catch (e) {
    return false;
  }
};

export const userHasPermission = (permissions) => {
  const user = localStorage.token ? jwtDecode(localStorage.token) : undefined;
  if (!user?.permissions) {
    return false;
  }
  // make a set out of the 2 permission arrays. If it is longer than the user's
  // permissions, than we know the user is missing at least one of the required permissions.
  const set = new Set(user.permissions.concat(permissions));
  return set.size === user.permissions.length;
};

export const logoutUser = () => {
  window?.analytics?.reset();
  localStorage.removeItem("adminPartnerId");
  localStorage.removeItem("token");
  sessionStorage.removeItem("url");
};

export const frontendAuth = (token, history) => {
  const user = decode(token);
  if (!user || !user.permissions) {
    logoutUser(history);
  }
};

export const COMMON_PARTNER_PATHS = [
  "/404",
  "/partner",
  "/partner/:country/:id_type",
  "/partner/account_settings",
  "/partner/analytics",
  "/partner/dashboard",
  "/partner/id_status",
  "/partner/job_list",
  "/partner/job_results/:environment/:id",
  "/partner/kyc/:environment/:userId",
  "/partner/support/tickets",
  "/partner/user_list",
  "/settings",
];

export const ACCESS_CONTROL_MAP = {
  auditor: {
    partner: COMMON_PARTNER_PATHS.concat([
      "/partner/billing",
      "/partner/billing/history",
    ]),
  },
  operator: {
    partner: COMMON_PARTNER_PATHS.concat(["/partner/web_app"]),
  },
};

export const canView = (user, paths) => {
  if (!user) return false;

  const userRole = user.permission_group.toLowerCase();
  const userType = user.type.toLowerCase();
  const rolePaths = ACCESS_CONTROL_MAP[userRole];

  if (userRole && !rolePaths) return true;

  const allowedPaths = rolePaths[userType];

  if (!allowedPaths) return true;
  return paths.every((p) => allowedPaths.includes(p));
};

export const canUserView = (paths) => {
  const user = decode(localStorage.getItem("token"));
  return canView(user, paths);
};

function Auth({ component: Component, path, token, preOTPUser, pageMeta }) {
  return (
    <Route
      path={path}
      render={(props) => {
        // for the create password page
        // I guess the components don't believe it exists if it wasn't there when
        // React began the update cycle, so we need to grab it from storage
        token = token || localStorage.token;
        preOTPUser = preOTPUser || localStorage.preOTPUser;

        const onTFALink =
          path === "/login/tfa" || path.includes("two-factor-authentication");

        if (preOTPUser && !onTFALink) {
          let user = preOTPUser;
          if (typeof preOTPUser === "string") {
            user = JSON.parse(preOTPUser);
          }
          const redirectURL = user.set_up_2FA
            ? "/set-up-two-factor-authentication"
            : "/login/tfa";

          return <Redirect to={redirectURL} />;
        }

        if (!token) {
          return (
            <>
              {pageMeta && (
                <Helmet>
                  {pageMeta.title && <title>{pageMeta.title}</title>}
                  {pageMeta.description && (
                    <meta name="description" content={pageMeta.description} />
                  )}
                  {pageMeta.robots && (
                    <meta name="robots" content={pageMeta.robots} />
                  )}
                </Helmet>
              )}
              <Component {...props} />
            </>
          );
        }

        const tokenInfo = decode(token);
        // there is no need to check if tokenInfo.exp exists, because
        // if it does not, the result will be NaN which will not
        // evaluate to be truthy when compared to 0.
        if (tokenInfo.exp - Date.now() / 1000 < 0) {
          logoutUser();
          return <Redirect to="/login" />;
        }
        // smileRedirect will be present if the user tried to access an authenticated view
        // page prior to logged in, or after being logged out.
        const smileRedirect = localStorage.getItem("smileRedirect");
        if (tokenInfo && smileRedirect) {
          localStorage.removeItem("smileRedirect");
          // if a partner is trying to follow a link to an admin page, any calls to the backend
          // would return a 401, so rather than allowing that, redirect to 404
          if (tokenInfo.type === "partner" && smileRedirect.includes("admin")) {
            return <Redirect to="/404" />;
          }
          return <Redirect to={smileRedirect} />;
        }
        const userType = tokenInfo.type;
        if (userType === "admin") {
          if (userHasPermission("partner full_read")) {
            return <Redirect to="/admin/access_logs" />;
          }
          return <Redirect to="/admin/reviews" />;
        }
        if (userType === "reviewer") {
          return <Redirect to="/reviewer" />;
        }
        if (userType === "partner") {
          if (window?.analytics?.identify) {
            window.analytics.alias(tokenInfo.user_id);
            window.analytics.identify(
              tokenInfo.user_id,
              {
                email: tokenInfo.email,
                company: {
                  id: tokenInfo.partner_id,
                  name: tokenInfo.partner_name,
                },
                user_type: tokenInfo.type,
                name: tokenInfo.name,
                createdAt: tokenInfo.created_at,
                sign_in_count: tokenInfo.sign_in_count,
              },
              {
                Intercom: {
                  hideDefaultLauncher: false,
                },
              },
            );

            window.intercomSettings = {
              custom_launcher_selector: "#open-intercom",
            };
          }
          return <Redirect to="/partner/dashboard" />;
        }
      }}
    />
  );
}

function Protected({
  component,
  path,
  token,
  enabledEnvToggle,
  viewAs,
  pageMeta,
}) {
  return (
    <Route
      exact
      path={path}
      render={(props) => (
        <Wrapper
          viewAs={viewAs}
          path={path}
          token={token}
          enabledEnvToggle={enabledEnvToggle}
          component={component}
          pageMeta={pageMeta}
          {...props}
        />
      )}
    />
  );
}

function Wrapper(props) {
  const context = useContext(DisplayEnvironment);
  const {
    component: Component,
    path,
    token,
    viewAs,
    enabledEnvToggle = false,
    pageMeta,
    ...restProps
  } = props;
  const user = decode(localStorage.getItem("token"));
  if (
    user.type === "partner" &&
    !canUserView(Array.isArray(path) ? path : [path])
  ) {
    return <Redirect to="/404" />;
  }

  useEffect(() => {
    // since we explicitly set viewAs as 'admin' then we need to remove adminPartnerId from localStorage
    if (viewAs === "admin") {
      localStorage.removeItem("adminPartnerId");
    }

    // check validity of auth token between route switching
    // determine what sidebar navigation to display when we switch between routes
    let _viewAs;
    if (!user || !user.permissions) {
      _viewAs = "unauthorized";
    } else if (
      user.type === "partner" ||
      (user.type === "admin" && localStorage.getItem("adminPartnerId"))
    ) {
      _viewAs = "partner";
    } else if (user.type === "admin") {
      _viewAs = "admin";
    }
    context.setViewAs(viewAs || _viewAs);
    context.setEnabled(enabledEnvToggle);
  }, []);
  // for the create password page
  const location = useLocation();
  if (!user || !user.permissions) {
    // if the user was trying to follow a link, or view a page and their token has expired
    // save the location so we can redirect them there after they log (back) in.
    localStorage.setItem(
      "smileRedirect",
      `${location.pathname}${location.search}`,
    );
    return <Redirect to="/login" />;
  }
  // this is relevant to pages that are specific to an environment such
  // as the job result page. We want the user to know which environment's
  // data they are looking at.
  const showEnvironment = props.path.includes("/:environment/");
  // if the url contains a param :environment, someone has followed a link
  // and we should make sure we are setting the component to the correct environment.
  // Changes in this value will result in the env slider state changing to the
  // correct value.
  const showProduction = props?.match?.params?.environment === "production";
  return (
    <>
      <Helmet>
        <title>
          {pageMeta?.title
            ? `${pageMeta.title} - Smile ID Portal`
            : "Smile ID Portal"}
        </title>

        <meta
          name="description"
          content={
            pageMeta?.description ||
            `${pageMeta?.title ? `${pageMeta.title} - Smile ID Portal` : "Smile ID Portal"}`
          }
        />

        <meta name="robots" content="noindex" />
      </Helmet>

      <DisplayEnvironment.Consumer>
        {({ environment, enabled }) => (
          <>
            {(enabled || showEnvironment) && (
              <div
                className="environment-banner"
                data-env={
                  // why cast to string? see the note in the component itself
                  String(environment) === "true" || showProduction
                    ? "production"
                    : "sandbox"
                }
              >
                <p>
                  You are in{" "}
                  {String(environment) === "true" || showProduction
                    ? "Production"
                    : "Sandbox"}
                  !
                </p>
              </div>
            )}

            <Component displayEnvironment={environment} {...restProps} />
          </>
        )}
      </DisplayEnvironment.Consumer>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.session.token,
  preOTPUser: state.session.preOTPUser,
});

export const AuthRoute = connect(mapStateToProps, null)(Auth);

export const ProtectedRoute = withRouter(
  connect(mapStateToProps, null)(Protected),
);
